<div *ngIf="!fromViewForm" class="activity-watches-tab">
  <nb-tabset class="tabs-content-area">
    <nb-tab *ngIf="showChats" >
      <div class="tabs-inner-con"></div>
      <!-- <div *ngIf="tableInfo" class="chat-wrapper" style="overflow: auto">
        <ngx-chat-tab (subscriber)="getSubscribers($event)" [viewCallLog]="true" class="w-100" [chatOpened]="true"
          [tableInfo]="tableInfo" [fromTask]="true" [createdRecordDate]="Data['createdAt']"
          [createdBy]="Data['createdBy']" [recordData]="recordData" [columnData]="formColumns">
        </ngx-chat-tab>
      </div> -->

      <div class="row" style="margin-top: -10px;">
        <div class="col-sm-5" style="border: 1px solid var(--text-color);margin-left: 30px;border-radius: 15px;background: #f0ebf28f;">
          <!-- Chat -->
          <div class="tabs-inner-con"></div>
        <div *ngIf="tableInfo" class="chat-wrapper" style="overflow: auto">
          <ngx-chat-tab [isActivity]="isActivity" [viewCallLog]="true" class="w-100" [chatOpened]="true"
            [tableInfo]="tableInfo" [fromTask]="true" [createdRecordDate]="Data['createdAt']"
            [createdBy]="Data['createdBy']" [recordData]="recordData" [columnData]="formColumns" [formHeight]="formHeight">
          </ngx-chat-tab>
        </div>
        </div>
        <div class="col-sm-1 col-md-1"></div>
        <div class="col-sm-5">
          <!-- Activity -->
          <div  class="onlyActivity" style="height: 560px;overflow: hidden;">
            <div class="tabs-inner-con"></div>
            <div *ngIf="tableInfo" class="chat-wrapper" style="overflow: auto;height: 580px;">
              <div *ngFor="let key of objectKeys(eventEmiterService.realData)|reverse"  class="activityContainer firstActivity">
                <div  *ngFor="let act of eventEmiterService.realData[key],let i=index">
                  <div>
                    <span *ngIf="i==0" class="activityDate font-weight-bold" [ngStyle]="{'color':systemConfigColor}">{{datePipe.transform(key, "M/d/yy")}}</span><br>
                    <ul style="list-style: none;" class="activityUl">
                      <li style="list-style: none;">
                        <span class="userIntitial rounded-circle"
                        [ngStyle]="act?.messageId?.sender?.userColor?{'background-color':act?.messageId?.sender?.userColor}:{'background-color':gradientColor}">{{getShortName(act?.sender)}}</span>
                        <div class="d-flex">
                          <div class="divider" [ngStyle]="{'background-color':systemConfigColor}"></div>
                          <p class="activities">{{act?.quote}}</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="noActivity" style="text-align:center; margin-top: -338px;" *ngIf="(eventEmiterService.realData | json) == '{}'">
            <h6>Activity not found</h6>
          </div>
        </div>
      </div>
    </nb-tab>
    <nb-tab *ngIf="showWatchers" tabTitle="Watchers" class="watchers-tab">
      <button nbButton status="info" aria-hidden="true" class="watcher" [matMenuTriggerFor]="menu"
        (menuOpened)="watcherMenuOpened()" (menuClosed)="watcherMenuClosed()" id="auto_trigger">
        Action ({{ subscribers.length }})
      </button>

      <mat-menu #menu="matMenu" yPosition="below">
        <button mat-menu-item first-top>
          <span class="li-head curson-pointer" (click)="selfSubsription()">
            <span class="icon-mar-lft"></span> {{ subscriptionText }}
          </span>
        </button>

        <button style="background-color: #ececf0" mat-menu-item class="last-bottom" (click)="$event.stopPropagation()"
          (keydown)="$event.stopPropagation()" class="curson-pointer">
          <ngx-watch-user [tableInfo]="tableInfo" [matMenu]="trigger" parentForm="dynamicForm"
            (valueChanged)="updateSubscribers($event)"></ngx-watch-user>
        </button>
      </mat-menu>

      <ul *ngIf="subscribers && subscribers.length">
        <li *ngFor="let sub of subscribers">
          {{ sub.firstName }} {{ sub.lastName }}

          <span class="left-margin curson-pointer" (click)="cancelSubscription(sub._id)">
            <i class="fa fa-times" aria-hidden="true" nbTooltip="Remove" nbTooltipTrigger="hover"
              nbTooltipStatus="primary"></i></span>
        </li>
      </ul>
      <span *ngIf="subscribers && subscribers.length == 0" class="no-watches">No Watchers found</span>
    </nb-tab>
  </nb-tabset>
</div>

<div *ngIf="fromViewForm" class="activity-watches-tab">
  <div *ngIf="showChats" style="height: 100%">
    <ul class="nav nav-tabs">
      <li class="activity"><a class="active" [ngClass]="{'active' : !isActivity}" data-toggle="tab" (click)="setIsActivity(false)" >Chat</a></li>
      <li class="activity"><a data-toggle="tab"  [ngClass]="{'active' : isActivity}"  (click)="setIsActivity(true)">Activity</a></li>
    </ul>
    <div class="tab-content">
      <div  id="Chat" class="tab-pane active" [ngClass]="{'active' : !isActivity}">
        <div class="tabs-inner-con"></div>
        <div *ngIf="tableInfo" class="chat-wrapper" style="overflow: auto">
          <ngx-chat-tab [isActivity]="isActivity" [viewCallLog]="true" class="w-100" [chatOpened]="true"
            [tableInfo]="tableInfo" [fromTask]="true" [createdRecordDate]="Data['createdAt']"
            [createdBy]="Data['createdBy']" [recordData]="recordData" [columnData]="formColumns" [formHeight]="formHeight">
          </ngx-chat-tab>
        </div>
      </div>
      <div id="activity" class="tab-pane" [ngClass]="{'active' : isActivity}" >
        <div class="tabs-inner-con"></div>
        <div *ngIf="tableInfo" class="chat-wrapper" style="overflow: auto;height: 580px;" [nbSpinner]="activityLoading"  nbSpinnerStatus="primary">
          <!-- <div class="creator">
            <span *ngIf="this.eventEmiterService.creatorData.type == 'user'" class="font-weight-bold" [ngStyle]="{'color':systemConfigColor,'font-size':'14px'}">{{datePipe.transform(eventEmiterService.creatorData.date, "M/d/yy")}}</span><br>
            <ul style="list-style: none;padding-left: 0%;margin-top: 5px;">
              <li style="list-style: none;">
                <span class="userIntitial rounded-circle" [ngStyle]="{'background-color':gradientColor}">{{ getShortName(eventEmiterService.creatorData.firstName +" "+ eventEmiterService.creatorData.lastName)}}</span>
                <div class="d-flex">
                  <div class="divider" [ngStyle]="{'background-color':systemConfigColor}"></div>
                  <p class="activitis">
                    {{eventEmiterService.creatorData.firstName +" "+ eventEmiterService.creatorData.lastName + " "+ eventEmiterService.creatorData.text}}<br>
                    <span>#{{eventEmiterService.creatorData.recordId[2]}}</span><br>
                    <span class="text-muted">{{datePipe.transform(eventEmiterService.creatorData.date, "h:mm a")}}</span>
                  </p>
                </div>
              </li>
            </ul>
          </div> -->
          <div *ngFor="let key of objectKeys(eventEmiterService.realData)|reverse"  class="activityContainer">
            <div  *ngFor="let act of eventEmiterService.realData[key],let i=index">
              <div>
                <span *ngIf="i==0" class="activityDate font-weight-bold" [ngStyle]="{'color':systemConfigColor}">{{datePipe.transform(key, "M/d/yy")}}</span><br>
                <ul style="list-style: none;" class="activityUl">
                  <li style="list-style: none;">
                    <span class="userIntitial rounded-circle"
                    [ngStyle]="act?.messageId?.sender?.userColor?{'background-color':act?.messageId?.sender?.userColor}:{'background-color':gradientColor}">{{getShortName(act?.sender)}}</span>
                    <div class="d-flex">
                      <div class="divider" [ngStyle]="{'background-color':systemConfigColor}"></div>
                      <p class="activities">{{act?.quote}}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="noActivity" style="text-align:center;bottom: 320px;position: relative;" *ngIf="(eventEmiterService.realData | json) == '{}'">
          <h7>Activity not found</h7>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!showChats">
    <h6 class="text-center mt-4" [ngStyle]="{'color':systemConfigColor}">Activity</h6>
    <div *ngIf="isActivity" class="onlyActivity">
      <div *ngIf="tableInfo" class="chat-wrapper" style="overflow: auto;display: none;">
        <ngx-chat-tab [isActivity]="isActivity" [viewCallLog]="true" class="w-100" [chatOpened]="true"
          [tableInfo]="tableInfo" [fromTask]="true" [createdRecordDate]="Data['createdAt']"
          [createdBy]="Data['createdBy']" [recordData]="recordData" [columnData]="formColumns" [formHeight]="formHeight">
        </ngx-chat-tab>
      </div>
      <div class="tabs-inner-con"></div>
      <div *ngIf="tableInfo" class="chat-wrapper" style="overflow: auto;height: 580px;">
        <div *ngFor="let key of objectKeys(eventEmiterService.realData)|reverse"  class="activityContainer">
          <div  *ngFor="let act of eventEmiterService.realData[key],let i=index">
            <div>
              <span *ngIf="i==0" class="activityDate font-weight-bold" [ngStyle]="{'color':systemConfigColor}">{{datePipe.transform(key, "M/d/yy")}}</span><br>
              <ul style="list-style: none;" class="activityUl">
                <li style="list-style: none;">
                  <span class="userIntitial rounded-circle"
                  [ngStyle]="act?.messageId?.sender?.userColor?{'background-color':act?.messageId?.sender?.userColor}:{'background-color':gradientColor}">{{getShortName(act?.sender)}}</span>
                  <div class="d-flex">
                    <div class="divider" [ngStyle]="{'background-color':systemConfigColor}"></div>
                    <p class="activities">{{act?.quote}}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="tabs-inner-con"></div>
  <div *ngIf="tableInfo" class="chat-wrapper" style="overflow: auto">
    <ngx-chat-tab (subscriber)="getSubscribers($event)" [viewCallLog]="true" class="w-100" [chatOpened]="true"
      [tableInfo]="tableInfo" [fromTask]="true" [createdRecordDate]="Data['createdAt']" [createdBy]="Data['createdBy']"
      [recordData]="recordData" [columnData]="formColumns">
    </ngx-chat-tab>
  </div> -->
</div>
