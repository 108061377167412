<div *ngIf="field.type != 'section' && field.type != 'injectSubForm'" class="two-column-con"
  [ngClass]="{'one-column-con': field.type == 'area' }">

  <ng-container *ngIf="field.type == 'autoNumber' && show[field.name] && field.startValue > 0">
    <!-- Label for field type autoNumber -->
    <span class="new-form-label txt-clr">{{ field.label }}:</span>

    <!-- View Field for type autoNumber -->
    <!-- <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
      <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
    </div> -->
    <div class="edit-class-form" *ngIf="this.tempData[field.name]" [innerHTML]="this.tempData[field.name]"></div>
  </ng-container>

  <ng-container *ngIf="field.type == 'rollUp' && show[field.name] && field.options && field.options.length">
    <!-- Label for field type rollUp -->
    <span class="new-form-label txt-clr">{{ field.label }}:</span>

    <!-- View Field for type rollUp -->
    <!-- <div matPrefix class="mat-matPrefix">
      <img *ngIf="field.icon" class="mat-icon notranslate material-icons mat-icon-no-color top04"
        src="{{ field.icon }}" />
    </div> -->
    <div class="edit-class-form" *ngIf="this.tempData[field.name] !== null && field.isCurrency"
      [innerHTML]="this.tempData[field.name] | currency"></div>
    <div class="edit-class-form" *ngIf="this.tempData[field.name] !== null && !field.isCurrency"
      [innerHTML]="this.tempData[field.name]"></div>
  </ng-container>

  <ng-container *ngIf="field.type == 'formula' && show[field.name] && field.options && field.options.length">
    <!-- Label for field type autoNumber -->
    <span class="new-form-label txt-clr">{{ field.label }}:</span>

    <!-- View Field for type autoNumber -->
    <!-- <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
      <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
    </div> -->
    <div class="edit-class-form" *ngIf="this.tempData[field.name] && field.isCurrency"
      [innerHTML]="this.tempData[field.name] | currency"></div>
    <div class="edit-class-form" *ngIf="this.tempData[field.name] && !field.isCurrency"
      [innerHTML]="this.tempData[field.name]"></div>
  </ng-container>

  <div [id]="field.name" (click)="showEditIcon(field);$event.stopPropagation()"
    *ngIf="field.name == 'zip' && show[field.name]">

    <ng-container *ngTemplateOutlet="SaveAndClose"></ng-container>

    <ng-container *ngIf="showEditFieldForInlineEdit[field.name]">
      <!-- Field input for type zip -->
      <mat-form-field appearance="outline" *ngIf="field.name == 'zip'" appearance="outline">
        <mat-label>{{ field.label }}</mat-label>
        <input matInput [type]="field.type" [(ngModel)]="this.tempData[field.name]" [readonly]="field.isReadOnly" />
        <!-- <ngx-help class="help-icon help-right" *ngIf="tutorials | helpPipe: field.label"
            [tutorial]="tutorials | helpPipe: field.label"></ngx-help> -->
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="!showEditFieldForInlineEdit[field.name]">
      <!-- Label for field type zip -->
      <span class="new-form-label txt-clr">{{ field.label }}:</span>

      <!-- View Field for type zip -->
      <!-- <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
        <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
      </div> -->
      <div class="edit-class-form" *ngIf="this.tempData[field.name]" [innerHTML]="this.tempData[field.name]"></div>
    </ng-container>
  </div>

  <ng-container *ngIf="field.name != 'zip'">
    <div [id]="field.name" (click)="showEditIcon(field);$event.stopPropagation()"
      *ngIf="inputFields.includes(field.type) && show[field.name]">

      <ng-container *ngTemplateOutlet="SaveAndClose"></ng-container>

      <ng-container *ngIf="showEditFieldForInlineEdit[field.name]">
        <!-- Field input for type of inputFields -->
        <mat-form-field class="phone-number 22" class="mb-20" appearance="outline" [floatLabel]="'never'">
          <mat-label>{{ field.label }}</mat-label>
          <!-- <div *ngIf="field.icon" matPrefix class="mat-matPrefix">
            <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
          </div> -->
          <input (keyup)="saveIconForFieldClicked(field,$event)" *ngIf="!field.isPhone" matInput
            placeholder="{{ field.label }}" [type]="field.type" [readonly]="field.isReadOnly"
            [(ngModel)]="this.tempData[field.name]" />
          <input (keyup)="saveIconForFieldClicked(field,$event)" *ngIf="field.isPhone" #item
            [(ngModel)]="this.tempData[field.name]" ngxNumberOnly matInput placeholder="{{ field.label }}"
            [type]="field.type" [readonly]="field.isReadOnly" />
          <!-- <ngx-help class="help-icon help-right" *ngIf="tutorials | helpPipe: field.label"
              [tutorial]="tutorials | helpPipe: field.label"></ngx-help> -->
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="!showEditFieldForInlineEdit[field.name]">
        <!-- Label for field type inputFields -->
        <span class="new-form-label txt-clr">{{ field.label }}:</span>

        <!-- View Field for type inputFields -->
        <!-- <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
          <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
        </div> -->
        <div class="edit-class-form" *ngIf="
            this.tempData[field.name] && field.name !== 'phoneNumber'
          ">
          <div *ngIf="!field.isPhone" [innerHTML]="this.tempData[field.name] | renderImage">
          </div>
          <div *ngIf="field.isPhone">
            {{ this.tempData[field.name] | phone }}
          </div>
        </div>
        <div class="edit-class-form" *ngIf="
            this.tempData[field.name] && field.name == 'phoneNumber'
          ">
          {{ this.tempData[field.name] | phone }}
        </div>
      </ng-container>
    </div>

    <div [id]="field.name" (click)="showEditIcon(field);$event.stopPropagation()"
      *ngIf="field.type == 'area' && show[field.name]">

      <ng-container *ngTemplateOutlet="SaveAndClose"></ng-container>

      <ng-container *ngIf="showEditFieldForInlineEdit[field.name]">
        <!-- Field input for type of area -->
        <mat-form-field class="mb-20 reminder-note textarea-hght-auto" appearance="outline">
          <mat-label>{{ field.label }}</mat-label>
          <textarea (keyup)="saveIconForFieldClicked(field,$event)" [rows]="field.textAreaLines" matInput
            [placeholder]="field.label" [readonly]="field.isReadOnly"
            [(ngModel)]="this.tempData[field.name]"></textarea>
          <!-- <ngx-help class="help-icon help-right" *ngIf="tutorials | helpPipe: field.label"
              [tutorial]="tutorials | helpPipe: field.label"></ngx-help> -->
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="!showEditFieldForInlineEdit[field.name]">
        <div class="note">
          <!-- <ng-container *ngTemplateOutlet="SaveAndClose"></ng-container> -->
          <!-- Label for field type area -->
          <span class="new-form-label text-dark noteLabel txt-clr">{{ field.label }}:</span>

          <!-- View Field for type area -->
          <!-- <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
            <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
          </div> -->
          <textarea class="edit-class-form textarea-val form-control noteTextarea mt-2" id="messageBox"
            [rows]="field.textAreaLines" *ngIf="field.type == 'area' && this.tempData[field.name]"
            [innerHTML]="this.tempData[field.name] | renderImage | inlineHtml" readonly
            [ngStyle]="{ 'border-color': systemConfigColor }"></textarea>
        </div>
      </ng-container>
    </div>

    <div [id]="field.name" (click)="showEditIcon(field);$event.stopPropagation()"
      *ngIf="field.type === 'dropdown' && show[field.name]">

      <ng-container *ngTemplateOutlet="SaveAndClose"></ng-container>

      <ng-container *ngIf="showEditFieldForInlineEdit[field.name]">
        <!-- Field input for type of dropdown -->
        <!-- <mat-form-field class="mb-20" appearance="outline"> -->
        <label>{{ field.label }}</label>
        <!-- <div *ngIf="field.icon" matPrefix class="mat-matPrefix">
            <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
          </div> -->
        <ng-container *ngIf="!field.allowMultipleValues">
          <ng-select [(ngModel)]="this.tempData[field.name]" [disabled]="field.isReadOnly" (change)="
            onDependentFieldChanged(
              $event,
              field,
              field.type,
              field.name,
              section,
              rowIndex
            )
          ">
            <ng-option *ngFor="let option of field.options" [value]="option" matRipple>
              {{ option }}
            </ng-option>
          </ng-select>
        </ng-container>
        <ng-container *ngIf="field.allowMultipleValues">
          <ng-select [(ngModel)]="this.tempData[field.name]" multiple [placeholder]="field.label"
            [disabled]="field.isReadOnly" (change)="
              onDependentFieldChanged(
                $event,
                field,
                field.type,
                field.name,
                section,
                rowIndex
              )
            ">
            <ng-option *ngFor="let option of field.options" [value]="option" matRipple>
              {{ option }}
            </ng-option>
          </ng-select>
        </ng-container>
        <!-- </mat-form-field> -->
      </ng-container>

      <ng-container *ngIf="!showEditFieldForInlineEdit[field.name]">
        <!-- Label for field type dropdown -->
        <span class="new-form-label txt-clr">{{ field.label }}:</span>

        <!-- View Field for type dropdown -->
        <!-- <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
          <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
        </div> -->
        <div class="edit-class-form textarea-val" *ngIf="this.tempData[field.name]"
          [innerHTML]="this.tempData[field.name] | renderImage"></div>
      </ng-container>
    </div>

    <ng-container *ngIf="field.type === 'gadget' && show[field.name]">
      <!-- Label for field type gadget -->
      <span class="new-form-label txt-clr">{{ field.label }}:</span>

      <!-- View Field for type gadget -->
      <!-- <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
        <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
      </div> -->
      <div class="edit-class-form" *ngIf="gadgetFieldValue" [innerHTML]="gadgetFieldValue"></div>
    </ng-container>

    <div (click)="showEditIcon(field);$event.stopPropagation()" *ngIf="field.type == 'date' && show[field.name]">

      <ng-container *ngTemplateOutlet="SaveAndClose"></ng-container>

      <ng-container *ngIf="showEditFieldForInlineEdit[field.name]">
        <!-- Field input for type of date -->
        <mat-form-field class="mb-20 due-date-col mat-dt-picker-modal" appearance="outline">
          <!-- <div *ngIf="field.icon" matPrefix class="mat-matPrefix">
            <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
          </div> -->
          <input matInput [matDatepicker]="formpicker" [placeholder]="" [(ngModel)]="this.tempData[field.name]"
            [readonly]="field.isReadOnly" />
          <mat-datepicker-toggle matSuffix [for]="formpicker">
          </mat-datepicker-toggle>
          <mat-datepicker #formpicker></mat-datepicker>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="!showEditFieldForInlineEdit[field.name]">
        <!-- Label for field type date -->
        <span class="new-form-label txt-clr">{{ field.label }}:</span>

        <!-- View Field for type date -->
        <!-- <div matPrefix *ngIf="field.icon" matPrefixclass="mat-matPrefix">
          <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
        </div> -->
        <div class="edit-class-form" *ngIf="
            (field.type == 'date' || field.type == 'dateTime') &&
            this.tempData[field.name]
          ">
          {{ this.tempData[field.name] | dateFormatWithTimeZone : 'date' }}
        </div>
      </ng-container>
    </div>

    <div (click)="showEditIcon(field);$event.stopPropagation()" *ngIf="field.type == 'dateTime' && show[field.name]">

      <ng-container *ngTemplateOutlet="SaveAndClose"></ng-container>

      <ng-container *ngIf="showEditFieldForInlineEdit[field.name]">
        <!-- Field input for type of dateTime -->
        <div class="date-time-pic-cus">
          <!-- <div *ngIf="field.icon" matPrefix class="mat-matPrefix">
            <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
          </div> -->
          <span class="date-time-pic-cus-title" [ngClass]="{ 'title-active': dateTimePickerToggeledOn }">{{ field.label
            }} :</span>
          <app-date-time-picker [field]="field" [disabled]="field.isReadOnly" [(ngModel)]="this.tempData[field.name]">
          </app-date-time-picker>
        </div>
      </ng-container>

      <ng-container *ngIf="!showEditFieldForInlineEdit[field.name]">
        <!-- Label for field type dateTime -->
        <span class="new-form-label txt-clr">{{ field.label }}:</span>

        <!-- View Field for type dateTime -->
        <!-- <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
          <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
        </div> -->
        <div class="edit-class-form" *ngIf="
            (field.type == 'date' || field.type == 'dateTime') &&
            this.tempData[field.name]
          ">
          {{ this.tempData[field.name] | dateCustomPipe }}
        </div>
      </ng-container>
    </div>

    <div [id]="field.name" (click)="showEditIcon(field);$event.stopPropagation()"
      *ngIf="field.type == 'status' && show[field.name]">

      <ng-container *ngTemplateOutlet="SaveAndClose"></ng-container>

      <ng-container *ngIf="showEditFieldForInlineEdit[field.name]">
        <!-- Field input for type of status -->
        <div class="status-buttonss-form possition-relative">
          <!-- <mat-form-field appearance="outline" *ngIf="field.type == 'status'"> -->
          <label><i class="fas fa-circle mr-1"></i>
            {{ statuses[field.name] ? statuses[field.name] : field.label }}
          </label>
          <ng-select (change)="onDone($event, field)" [disabled]="field.isReadOnly"
            [(ngModel)]="this.tempData[field.name]">
            <!-- <div class="px-3">
                <mat-form-field>
                  <input matInput #statusSearch placeholder="Search" (click)="$event.stopPropagation()" />
                </mat-form-field>
              </div> -->
            <ng-option [class.active]="status.status === statuses[field.name]" *ngFor="
                  let status of field.statusOptions" [value]="status">
              <i class="fas fa-circle" [ngStyle]="{ color: status.color }"></i>
              {{ status.status }}
            </ng-option>
          </ng-select>
          <!-- </mat-form-field> -->
        </div>
      </ng-container>

      <ng-container *ngIf="!showEditFieldForInlineEdit[field.name]">
        <!-- Label for field type status -->
        <span class="new-form-label txt-clr">{{ field.label }}:</span>

        <!-- View Field for type status -->
        <!-- <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
          <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
        </div> -->
        <div class="edit-class-form" *ngIf="this.tempData[field.name]" [innerHTML]="this.tempData[field.name]"></div>
      </ng-container>
    </div>

    <div *ngIf="field.type == 'checkList' && show[field.name]">

      <div class="to-do-list-field">

        <!-- Field input for type of number -->

        <ng-container *ngIf="checkListArray && checkListArray.length">
          <ng-container *ngFor="let parent of checkListArray;let i = index">

            <div *ngIf="showList[i]" class="checklist-row-data">
              <span (click)="removeWholeCheckList(i)" style="z-index: 9999;"
                class="material-icons pull-right cursor-pointer mat-custom close-new-form pos-abs txt-clr"
                [style.color]="this.systemConfigColor" nbTooltip="Delete checklist" nbTooltipPlacement="top"
                nbTooltipStatus="basic" nbTooltipTrigger="hover">
                close
              </span>
              <h4>{{parent.name}}</h4>

              <span class="add-new-item-checklist" (click)="addItemInsideCheck(i)">+ Add New Item</span>
              <div *ngFor="let item of parent.value;let ind = index" id="{{item}}-{{ind}}" class="checklist-row"
                style="display: flex;">

                <nb-checkbox [checked]="item.checked" (change)="onCheckedItem($event,item,ind,i)"
                  class="inner_checkbox">
                  {{ item.item }}
                </nb-checkbox>

                <input *ngIf="showItemInput[i][ind]" type="text" (keyup)="bindItemOfCheckList($event,i,ind)"
                  class="form-control border-clr" placeholder="Enter item name">

                <span (click)="removeItemFromCheckList(i,ind)"
                  class="material-icons pull-right mat-custom cursor-pointer close-new-form txt-clr"
                  [style.color]="this.systemConfigColor" nbTooltip="Remove item" nbTooltipPlacement="top"
                  nbTooltipStatus="basic" nbTooltipTrigger="hover">
                  close
                </span>

              </div>
            </div>


            <div class="pos-rel-new">
              <input *ngIf="showNameInput[i]" type="text" (keyup)="bindNameOfCheckList($event,i)"
                class="form-control checklist-input border-clr" placeholder="Enter checklist name">
              <span id="cross-{{i}}" *ngIf="showNameInput[i]" (click)="hideNameInput(i)"
                class="material-icons pull-right cursor-pointer mat-custom close-new-form pos-abs txt-clr"
                [style.color]="this.systemConfigColor">
                close
              </span>
            </div>

          </ng-container>
        </ng-container>


        <button style="margin-top: 17px;" (click)="addNewCheckList()" nbButton matRipple
          class="btn provide-btn txt-clr">
          + Add {{field.label}}
        </button>


      </div>

    </div>

    <div [id]="field.name" (click)="showEditIcon(field);$event.stopPropagation()"
      *ngIf="field.type == 'checkbox' && show[field.name]">

      <ng-container *ngTemplateOutlet="SaveAndClose"></ng-container>

      <ng-container *ngIf="showEditFieldForInlineEdit[field.name]">
        <!-- Field input for type of checkbox -->
        <div class="checkbox mb-20">
          <ng-container *ngFor="let item of field.options; let i = index">
            <nb-checkbox [(ngModel)]="this.dataForCheckbox[field.name][i]" id="{{i}}" class="inner_checkbox"
              [disabled]="field.isReadOnly" [checked]="this.dataForCheckbox[field.name][i]" (change)="
              onDependentFieldChanged(
                $event,
                field,
                field.type,
                field.name,
                section,
                rowIndex,
                field
              )
            ">
              {{ item }}
            </nb-checkbox>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="!showEditFieldForInlineEdit[field.name]">
        <!-- Label for field type checkbox -->
        <span class="new-form-label txt-clr">{{ field.label }}:</span>

        <!-- View Field for type checkbox -->
        <!-- <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
          <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
        </div> -->
        <div class="edit-class-form" *ngIf="this.tempData[field.name]" [innerHTML]="this.tempData[field.name]"></div>
      </ng-container>
    </div>

    <ng-container *ngIf="field.type == 'recordType' && show[field.name]">
      <!-- Label for field type recordType -->
      <span class="new-form-label txt-clr">{{ field.label }}:</span>

      <!-- View Field for type recordType -->
      <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
        <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
      </div>
      <div class="edit-class-form" *ngIf="this.tempData[field.name]" [innerHTML]="this.tempData[field.name]"></div>
    </ng-container>

    <div [id]="field.name" (click)="showEditIcon(field);$event.stopPropagation()"
      *ngIf="field.type == 'radio' && show[field.name]">

      <ng-container *ngTemplateOutlet="SaveAndClose"></ng-container>

      <ng-container *ngIf="showEditFieldForInlineEdit[field.name]">
        <!-- Field input for type of recordType -->
        <div class="checkbox mb-20 check-radio">
          <nb-radio-group class="radio" name="my-radio-group-{{ field.name }}" [(ngModel)]="this.tempData[field.name]"
            [disabled]="field.isReadOnly">
            <nb-radio (valueChange)="
            onDependentFieldChanged(
              $event,
              field,
              field.type,
              field.name,
              section,
              rowIndex
            )
          " *ngFor="let item of field.options" [value]="item">
              {{ item }}
            </nb-radio>
          </nb-radio-group>
        </div>
      </ng-container>

      <ng-container *ngIf="!showEditFieldForInlineEdit[field.name]">
        <!-- Label for field type recordType -->
        <span class="new-form-label txt-clr">{{ field.label }}:</span>

        <!-- View Field for type recordType -->
        <!-- <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
          <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
        </div> -->
        <div class="edit-class-form" *ngIf="this.tempData[field.name]" [innerHTML]="this.tempData[field.name]"></div>
      </ng-container>
    </div>

    <div [id]="field.name" (click)="showEditIcon(field);$event.stopPropagation()"
      *ngIf="field.type == 'richTextArea' && show[field.name]">

      <ng-container *ngTemplateOutlet="SaveAndClose"></ng-container>

      <ng-container *ngIf="showEditFieldForInlineEdit[field.name]">
        <!-- Field input for type of recordType -->
        <div class="l text-area-con mb-20">
          <span class="position-relative with-help">{{ field.label }}&nbsp; &nbsp;:
          </span>
          <editor [init]="{
              plugins: 'autoresize',
              toolbar: false,
              skin_url: 'assets/skins/lightgray'
            }" apiKey="3zk4lcdxv42ueqfotvbtw7lihq6uwkb0cy7l33muhxhr778z" [(ngModel)]="this.tempData[field.name]">
          </editor>
        </div>
      </ng-container>

      <ng-container *ngIf="!showEditFieldForInlineEdit[field.name]">
        <!-- Label for field type recordType -->
        <span class="new-form-label txt-clr">{{ field.label }}:</span>

        <!-- View Field for type recordType -->
        <!-- <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
          <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
        </div> -->
        <div class="edit-class-form" (click)="onFilePreivew(this.tempData[field.name])"
          *ngIf="this.tempData[field.name] && checkURL(this.tempData[field.name])"
          [innerHTML]="this.tempData[field.name] | renderImage"></div>
        <div class="edit-class-form" *ngIf="this.tempData[field.name] && !checkURL(this.tempData[field.name])"
          [innerHTML]="this.tempData[field.name]"></div>
      </ng-container>
    </div>

    <div class="fltt-left mb-20" (dblclick)="showEditIcon(field);$event.stopPropagation()"
      *ngIf="field.type == 'lookup' && show[field.name] && !field.loadAsDropDown" appearance="outline">

      <ng-container *ngTemplateOutlet="SaveAndClose"></ng-container>

      <ng-container *ngIf="showEditFieldForInlineEdit[field.name]">
        <!-- Field input for type of lookup -->
        <div class="select-new">
          <mat-label>{{ field.label }} </mat-label>

          <ng-select [items]="filteredOptions[field.name]" bindLabel="value" #in (keyup)="dynamicSearch($event, field)"
            appendTo="#{{ field.name }}" [notFoundText]="dropDownText[field.name]"
            [ngModelOptions]="{ standalone: true }" [id]="field.name" placeholder="{{ field.label }}"
            [compareWith]="compareFn" [maxSelectedItems]="!field.allowMultipleValues ? 1 : undefined" (change)="
              onSelectionChange(lookupValue[field.name], field, field.sequence)
            " [multiple]="true" [(ngModel)]="lookupValue[field.name]" clearable="true" class="ng-select-latest"
            [ngClass]="{
              'single-select':
                field.allowMultipleValues == null ||
                field.allowMultipleValues == false
            }">
            <ng-template ng-footer-tmp>
              <div *ngIf="loadingAPI" [nbSpinner]="true" nbSpinnerStatus="info">
                Loading ...
              </div>
              <!-- <div (click)="in.close(); addSubForm(field, field.sequence)" class="hover-addnew">
                Add New {{ field.lookupTableName | ngxPlural: 1000 }}
              </div> -->
            </ng-template>
            <ng-template ng-label-tmp let-item="item">
              <div (click)="
                  onShowLookupDetail(
                    $event,
                    field,
                    item,
                    item.id,
                    editNotLoadAsDropdownLookupPopover,
                    'editNotLoadAsDropdownLookupPopover'
                  )
                " [ngbPopover]="templateRef5" placement="bottom" container="body"
                #editNotLoadAsDropdownLookupPopover="ngbPopover" [autoClose]="'outside'">
                <i class="fa fa-window-close" (click)="removeSelected(item, field)">x</i>
                <b style="font-size: 16px" [innerHTML]="item.value | renderImage"></b>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </ng-container>

      <ng-container *ngIf="!showEditFieldForInlineEdit[field.name]">
        <!-- Label for field type lookup -->
        <span class="new-form-label txt-clr">{{ field.label }}:</span>

        <!-- View Field for type lookup -->
        <!-- <div matPrefix class="mat-matPrefix" *ngIf="field.icon">
          <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
        </div> -->

        <div class="chipset-custom-main">
          <div style="cursor: pointer" (click)="
              onShowLookupDetail(
                $event,
                field,
                this.tempData,
                val.lookupId,
                notLoadAsDropdownLookupPopover,
                'notLoadAsDropdownLookupPopover'
              )
            " [ngbPopover]="templateRef1" placement="bottom" container="body"
            #notLoadAsDropdownLookupPopover="ngbPopover" [autoClose]="'outside'"
            *ngFor="let val of lookupDataObject[field.name]" class="chipset-custom edit-class-form dot_line">
            <ng-container *ngFor="let value of val.valueToDisplay; let ind = index">
              <span *ngIf="ind == 0 && ind == val.valueToDisplay.length - 1" [innerHTML]="value | renderImage"></span>
              <span *ngIf="ind != 0 && ind == val.valueToDisplay.length - 1" [innerHTML]="value | renderImage"></span>
              <span *ngIf="ind != 0 && ind != val.valueToDisplay.length - 1" [innerHTML]="value | renderImage"></span>
              <span *ngIf="ind == 0 && ind != val.valueToDisplay.length - 1" [innerHTML]="value | renderImage"></span>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-template #templateRef1>
        <ngx-lookup-modal-detail id="pop-open1" (viewModalEmit)="onEmitFromLookupPopoverForModal($event)"
          [data]="lookupDetailItem" [tableIdFromView]="field.lookupTableId" [fromView]="true"
          [name]="fieldNameForLookup" [tableData]="tableDataFromViewToForm" [dataForLookupDetail]="dataForLookupDetail"
          [tableName]="tableForLookupDetail" [formColumns]="formColumns">
        </ngx-lookup-modal-detail>
      </ng-template>
    </div>

    <div [id]="field.name" (dblclick)="showEditIcon(field);$event.stopPropagation()" class="fltt-left"
      *ngIf="field.type == 'lookup' && show[field.name] && field.loadAsDropDown" appearance="outline">

      <ng-container *ngTemplateOutlet="SaveAndClose"></ng-container>

      <ng-container *ngIf="showEditFieldForInlineEdit[field.name]">
        <div class="select-new">
          <mat-label>{{ field.label }}
          </mat-label>
          <ng-select [items]="
            filteredOptions[field.name]
              | filter: lookupValue[field.name]:in.searchTerm
          " bindLabel="value" #in appendTo="#{{field.name}}" [notFoundText]="'No record found'"
            (ngModelChange)="updatedVal($event, field.name)" [ngModelOptions]="{ standalone: true }" [id]="field.name"
            placeholder="{{ field.label }}" [compareWith]="compareFn" [maxSelectedItems]="
            !field.allowMultipleValues ? 1 : undefined
          " (change)="
            onSelectionChange(
              lookupValue[field.name],
              field,
              field.sequence
            )
          " [multiple]="true" [(ngModel)]="lookupValue[field.name]" clearable="true" class="ng-select-latest"
            [ngClass]="{
            'single-select':
              field.allowMultipleValues == null ||
              field.allowMultipleValues == false
          }">
            <ng-template ng-option-tmp let-item="item" let-index="index" let-item$="item$">
              <div *ngIf="item.value">
                <input type="checkbox" [checked]="item$.selected" />
                <span class="checkmark"></span>
                <label class="form-check-label ml-2" [innerHTML]="item.value | renderImage : true"></label>
              </div>
            </ng-template>
            <ng-template ng-footer-tmp>
              <div *ngIf="loadingAPI" [nbSpinner]="true" nbSpinnerStatus="info">
                Loading ...
              </div>
              <!-- <div (click)="
                in.close(); addSubForm(field, field.sequence)
              " class="hover-addnew">
                Add New {{ field.lookupTableName | ngxPlural : 1000 }}
              </div> -->
            </ng-template>
            <ng-template ng-label-tmp let-item="item">
              <div
                (click)="onShowLookupDetail($event,field,item,item.id,editLoadAsDropdownLookupPopover, 'editLoadAsDropdownLookupPopover')"
                [ngbPopover]="templateRef4" placement="bottom" container="body"
                #editLoadAsDropdownLookupPopover="ngbPopover" [autoClose]="'outside'">
                <i class="fa fa-window-close" (click)="removeSelected(item, field)">x</i>
                <b style="font-size: 16px" [innerHTML]="item.value | renderImage"></b>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </ng-container>

      <ng-container *ngIf="!showEditFieldForInlineEdit[field.name]">
        <!-- Label for field type lookup -->
        <span class="new-form-label txt-clr">{{ field.label }}:</span>

        <!-- View Field for type lookup -->
        <!-- <div matPrefix class="mat-matPrefix" *ngIf="field.icon">
          <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
        </div> -->
        <div class="chipset-custom-main">
          <div style="cursor: pointer" (click)="
              onShowLookupDetail(
                $event,
                field,
                this.tempData,
                val.lookupId,
                loadAsDropdownLookupPopover,
                'loadAsDropdownLookupPopover'
              )
            " #loadAsDropdownLookupPopover="ngbPopover" [ngbPopover]="templateRef3" container="body" placement="bottom"
            [autoClose]="'outside'" *ngFor="let val of lookupDataObject[field.name]"
            class="chipset-custom edit-class-form dot_line">
            <ng-container *ngFor="let value of val.valueToDisplay; let ind = index">
              <span *ngIf="ind == 0 && ind == val.valueToDisplay.length - 1" [innerHTML]="value | renderImage"></span>
              <span *ngIf="ind != 0 && ind == val.valueToDisplay.length - 1" [innerHTML]="value | renderImage"></span>
              <span *ngIf="ind != 0 && ind != val.valueToDisplay.length - 1" [innerHTML]="value | renderImage"></span>
              <span *ngIf="ind == 0 && ind != val.valueToDisplay.length - 1" [innerHTML]="value | renderImage"></span>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <ng-template #templateRef3>
        <ngx-lookup-modal-detail (viewModalEmit)="onEmitFromLookupPopoverForModal($event)" [data]="lookupDetailItem"
          [tableIdFromView]="field.lookupTableId" [fromView]="true" [name]="fieldNameForLookup"
          [tableData]="tableDataFromViewToForm" [dataForLookupDetail]="dataForLookupDetail"
          [tableName]="tableForLookupDetail" [formColumns]="formColumns">
        </ngx-lookup-modal-detail>
      </ng-template>
    </div>

    <div [id]="field.name" *ngIf="field.type == 'file' && show[field.name]">

      <ng-container *ngTemplateOutlet="SaveAndClose"></ng-container>

      <ng-container *ngIf="showEditFieldForInlineEdit[field.name]">
      </ng-container>

      <ng-container *ngIf="!showEditFieldForInlineEdit[field.name]">
        <!-- Label for field type file -->
        <span class="new-form-label txt-clr">{{ field.label }}:</span>

        <!-- View Field for type file -->
        <!-- <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
          <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
        </div> -->
        <div class="edit-class-form" *ngIf="this.tempData[field.name] && this.tempData[field.name].length">
          <span (click)="onFilePreivew(item)" *ngFor="let item of this.tempData[field.name]"
            [innerHTML]="item | renderImage"></span>
        </div>
      </ng-container>
    </div>

    <div *ngIf="field.type === 'time' && show[field.name]">

      <ng-container *ngTemplateOutlet="SaveAndClose"></ng-container>

      <ng-container *ngIf="showEditFieldForInlineEdit[field.name]">
        <!-- Field input for type of time -->
        <mat-form-field class="mb-20" appearance="outline">
          <mat-label>{{ field.label }}</mat-label>
          <input matInput placeholder="{{ field.label }}" [type]="field.type" [placeholder]="field.label"
            [(ngModel)]="this.tempData[field.name]" [readonly]="field.isReadOnly" />
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="!showEditFieldForInlineEdit[field.name]">
        <!-- Label for field type time -->
        <span class="new-form-label txt-clr">{{ field.label }}:</span>

        <!-- View Field for type time -->
        <!-- <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
          <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
        </div> -->
        <div class="edit-class-form" *ngIf="this.tempData[field.name]" [innerHTML]="this.tempData[field.name]"></div>
      </ng-container>
    </div>

    <div [id]="field.name" (click)="showEditIcon(field);$event.stopPropagation()" class="fltt-left mb-20"
      *ngIf="field.type === 'dropdownWithImage' && show[field.name]">

      <ng-container *ngTemplateOutlet="SaveAndClose"></ng-container>

      <ng-container *ngIf="showEditFieldForInlineEdit[field.name]">
        <!-- Field input for type of dropdownWithImage -->
        <ng-container *ngIf="!field.allowMultipleValues">
          <div class="select-new">
            <!-- <div *ngIf="field.icon" matPrefix class="mat-matPrefix">
              <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
            </div> -->
            <mat-label>{{ field.label }}</mat-label>
            <ng-select [readonly]="field.isReadOnly" [(ngModel)]="this.tempData[field.name]"
              appendTo="#{{ field.name }}" [id]="field.name" [clearable]="false" placeholder="{{ field.label }}"
              class="ng-select-latest single-select" (change)="
              onDependentFieldChanged(
                $event,
                field,
                field.type,
                field.name,
                section,
                rowIndex
              )
            ">
              <ng-option *ngFor="let val of field.options" [value]="val.title">
                <div class="drop-down-img-col">
                  <img [src]="val.image" alt="" height="18" width="18" />
                  <span [innerHTML]="val.title | renderImage" class="select-text"></span>
                </div>
              </ng-option>
            </ng-select>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!showEditFieldForInlineEdit[field.name]">
        <!-- Label for field type time -->
        <span class="new-form-label txt-clr">{{ field.label }}:</span>

        <!-- View Field for type dropdownWithImage -->
        <!-- <div matPrefix class="mat-matPrefix">
          <img *ngIf="field.type == 'dropdownWithImage'"
            class="mat-icon notranslate material-icons mat-icon-no-color top04"
            src="{{ imageForDropdownWithImageView }}" />
        </div> -->
        <div class="edit-class-form textarea-val" (click)="onFilePreview(this.tempData[field.name])"
          *ngIf="this.tempData[field.name] && checkURL(this.tempData[field.name])"
          [innerHTML]="this.tempData[field.name] | renderImage"></div>
        <div class="edit-class-form textarea-val"
          *ngIf="this.tempData[field.name] && !checkURL(this.tempData[field.name])"
          [innerHTML]="this.tempData[field.name]"></div>
      </ng-container>
    </div>

    <!-- Code for reference button -->
    <!-- <ng-container class="l" appearance="outline" *ngIf="field.type === 'refButton' ">
        <ng-container *ngIf="list && list.length">
          <div class="pos-rel" *ngFor="let ele of list; let i = index">
            <div class="row heading">
              <span >{{ ele.name }}</span>
            </div>
            <div class="field-box">
              <div
                (click)="onShowLookupDetail($event,ele,this.tempData,item.id, referenceLookupPopover, 'referenceLookupPopover')"
                [autoClose]="'outside'" #referenceLookupPopover="ngbPopover" [ngbPopover]="templateRef2"
                container="body" placement="bottom" style="cursor: pointer;"
                *ngFor="let item of ele.value; let row = index" class="dot_line edit-class-form">
                <span *ngFor="let val of item.idField" class="ui-content1" [innerHTML]="val"></span>
                <span *ngFor="let value of item.others" class="ui-content2" [innerHTML]="value"></span>
              </div>
            </div>
          </div>
          <ng-template #templateRef2>
            <ngx-lookup-modal-detail id="pop-open2"
              (viewModalEmit)="onEmitFromLookupPopoverForModal($event)" [data]="lookupDetailItem"
              [tableIdFromView]="tableIdFromView" [fromView]="true"
              [name]="fieldNameForLookup" [tableData]="tableDataFromViewToForm"
              [dataForLookupDetail]="dataForLookupDetail" [tableName]="tableForLookupDetail"
              [formColumns]="this.form[tableName]">
            </ngx-lookup-modal-detail>
          </ng-template>

        <button (click)="addRef(field.name)" nbButton matRipple class="btn btn-primary">
          + Add Reference
        </button>
      </ng-container>
    </ng-container> -->
  </ng-container>
</div>

<div *ngIf="field.type != 'section' && i != 0 && (i + 1) % 2 != 0" [ngClass]="{ clearfix: true }"></div>

<ng-template #SaveAndClose>
  <div style="cursor: pointer" class="editable-field">
    <!-- <span (click)="editIconForFieldClicked(field)" *ngIf="showEditIconForField[field.name] && !showEditFieldForInlineEdit[field.name]"
      class="material-icons-outlined edit-icon">
      edit
    </span> -->
  </div>
  <div class="pull-right done-close-con">
    <span (click)="saveIconForFieldClicked(field)" *ngIf="showEditFieldForInlineEdit[field.name]"
      class="material-icons-outlined">
      done
    </span>
    <span (click)="cancelIconForFieldClicked(field);$event.stopPropagation()"
      *ngIf="showEditFieldForInlineEdit[field.name]" class="material-icons-outlined">
      close
    </span>
  </div>
</ng-template>
