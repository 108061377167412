<nb-card id="nb-card-{{ tableName }}-{{ currentTimeStamp }}" [ngClass]="{
    'modal-body': true,
    'project-pop-up': true,
    'enabled-view-mode': viewFlag || editViewFlag,
    'only-view-mode': viewFlag,
    'add-form-con': addEditFlag,
    'edit-view-mode': editViewFlag,
    'align-set-left': fieldAlignment && fieldAlignment == 'left',
    'align-set-center': fieldAlignment && fieldAlignment == 'center',
    'align-set-right': fieldAlignment && fieldAlignment == 'right',
    'align-set-default': fieldAlignment && fieldAlignment == 'default',
    'one-row-one-col':
      fieldAlignment &&
      (fieldAlignment == 'right' ||
        fieldAlignment == 'center' ||
        fieldAlignment == 'left')
  }" [nbSpinner]="loading" nbSpinnerSize="large" nbSpinnerStatus="primary" class="new-form-design" cdkDrag>
  <nb-card-header [style.color]="this.systemConfigColor" cdkDragHandle>
    <ng-container *ngIf="viewFlag && activeAction && activeAction.length">
      <button *ngFor="let action of activeAction; let i = index" class="btn markAsComplete" [ngStyle]="{
        'border-color': gradientColor,
        color: gradientColor,
        'background-color': action.hover == true ? gradientColor : 'white',
        color: action.hover == true ? 'white' : gradientColor
      }" (click)="markComplete(action)" (mouseover)="action.hover = true" (mouseleave)="action.hover = false">
        {{ action.actionName }}
      </button>
    </ng-container>

    <span *ngIf="!Data" class="header-title-new">{{ this.title }}</span>

    <span *ngIf="idValues && Data" class="header-title-new" [ngClass]="{
        'with-fade': ((this.idValues.length * 9) / 50) * 100 > this.formWidth
      }">{{ this.idValues }}
    </span>

    <nb-action *ngIf="viewFlag || editform || editViewFlag" class="reminder" matRipple
      [ngStyle]="{ color: systemConfigColor }" (click)="openReminderModal()">
      <i class="far fa-bell"></i>
    </nb-action>
    <span (click)="closeModal()" class="material-icons pull-right cursor-pointer mat-custom close-new-form"
      *ngIf="!inlineView" [style.color]="this.systemConfigColor">
      cancel
    </span>

    <span class="watche-users-main pull-right">
      <span class="watche-users-inline">
        <span id="watcher-span-{{ i }}" *ngFor="let option of subscribers; let i = index" [ngStyle]="{
            background: option?.userColor
              ? option?.userColor
              : systemConfigColor
          }" nbTooltip="{{ option.firstName + ' ' + option.lastName }}" nbTooltipPlacement="top"
          nbTooltipStatus="basic" nbTooltipTrigger="hover" class="round-user">
          {{ option.firstName[0] + option.lastName[0] }}
        </span>
      </span>
    </span>

    <span class="material-icons-sharp border-dotted mat-custom add-watcher pull-right" [matMenuTriggerFor]="menu"
      (menuOpened)="watcherMenuOpened()" (menuClosed)="watcherMenuClosed()">
      add
    </span>

    <mat-menu #menu="matMenu" yPosition="below">
      <button mat-menu-item first-top>
        <span class="li-head curson-pointer" (click)="selfSubsription()">
          <span class="icon-mar-lft"></span> {{ subscriptionText }}
        </span>
      </button>

      <div class="all-user-list">
        <button mat-menu-item *ngFor="let option of subscribers">
          <span class="li-head">
            <span class="icon-mar-lft" [ngStyle]="{ 'background-color': option?.userColor }">{{ option.firstName[0] +
              option.lastName[0] | uppercase }}</span>
            {{ option.firstName + " " + option.lastName }}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span class="left-margin curson-pointer" (click)="cancelSubscription(option._id)">
              <i class="fa fa-times" aria-hidden="true"></i>
            </span>
          </span>
        </button>
      </div>

      <button style="background-color: #ececf0" mat-menu-item class="last-bottom" (click)="$event.stopPropagation()"
        (keydown)="$event.stopPropagation()" class="curson-pointer">
        <ngx-watch-user [tableInfo]="tableInfo" [matMenu]="trigger" parentForm="dynamicForm"
          (valueChanged)="updateSubscribers($event)"></ngx-watch-user>
      </button>
    </mat-menu>
  </nb-card-header>

  <nb-card-body id="nb-card-body-{{ tableName }}-{{ currentTimeStamp }}" class="main_body customHeight" [ngClass]="{
      'acordian-form': sections && sections.length,
      'custom-height': formHeight
    }" [style.height.px]="formHeight ? formHeight : null">
    <div class="main-field-row"></div>
    <ng-container class="add-edit-form">
      <div class="container-fluid">
        <div class="view-left-side" [ngClass]="{
            'custom-height': (viewFlag || editViewFlag) && formHeight
          }" [style.height.px]="
            (viewFlag || editViewFlag) && formHeight ? formHeight : null
          ">
          <a class="txt-clr edit-anchor-icon">
            <span *ngIf="viewFlag" (click)="editDynamicTable()" class="mat-custom material-icons-outlined"
              nbTooltip="Edit record" nbTooltipPlacement="top" nbTooltipStatus="basic" nbTooltipTrigger="hover">
              edit
            </span>
          </a>

          <ng-container *ngIf="addEditFlag || editViewFlag">
            <!--Without section-->
            <ng-container *ngIf="sections.length == 0">
              <ng-container [ngTemplateOutlet]="formFields" [ngTemplateOutletContext]="{
                  finalArray: finalArrayForWithoutSection,
                  section: true
                }">
              </ng-container>
            </ng-container>

            <!--With section-->
            <ng-container *ngIf="sections && sections.length">
              <ng-container *ngFor="let section of sections; let secIndex = index">
                <mat-accordion class="example-headers-align">
                  <span class="three-dots-menu">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item>
                        <mat-icon>dialpad</mat-icon>
                        <span>Redial</span>
                      </button>
                      <button mat-menu-item disabled>
                        <mat-icon>voicemail</mat-icon>
                        <span>Check voice mail</span>
                      </button>
                      <button mat-menu-item>
                        <mat-icon>notifications_off</mat-icon>
                        <span>Disable alerts</span>
                      </button>
                    </mat-menu>
                  </span>
                  <mat-expansion-panel [expanded]="
                      stepperIndex ? stepperIndex == secIndex : step == secIndex
                    " (opened)="setStep(secIndex)" hideToggle>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        {{ section[0].label }}

                        <span class="material-icons-outlined extend-icon">
                          expand_more
                        </span>
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="form-body" [style.height.px]= "formHeight" >
                      <ng-container [ngTemplateOutlet]="formFields" [ngTemplateOutletContext]="{
                          finalArray: section.array,
                          section: false
                        }">
                      </ng-container>
                    </div>
                    <mat-action-row>
                      <button class="filled-dynamic-color" *ngIf="!viewFlag && secIndex == sections.length - 1"
                        (click)="submit()" nbButton matRipple>
                        Save
                      </button>
                      <button nbButton class="filled-dynamic-color" (click)="nextStep()"
                        *ngIf="secIndex != sections.length - 1">
                        Next
                      </button>
                      <button class="outline-dynamic-color" nbButton *ngIf="secIndex !== 0" (click)="prevStep()">
                        Back
                      </button>
                    </mat-action-row>
                  </mat-expansion-panel>
                </mat-accordion>
              </ng-container>
              <!-- </nb-stepper> -->
            </ng-container>
          </ng-container>

          <ng-container *ngIf="viewFlag">
            <ng-container [ngTemplateOutlet]="formFields" [ngTemplateOutletContext]="{
                finalArray: finalArrayForViewMode,
                section: true
              }">
            </ng-container>
          </ng-container>
        </div>

        <div class="shadow-div" *ngIf="editViewFlag && !inlineView"></div>
        <div class="view-right-side" *ngIf="(viewFlag || editViewFlag) && !inlineView">
          <ngx-activity-watchers [showChats]="showChats" [tableInfo]="tableInfo" [subscribers]="subscribers"
            [subscriptionText]="subscriptionText" [isSelfSubscribed]="isSelfSubscribed" [tableName]="tableName"
            [Data]="Data" [recordData]="recordData" [fromViewForm]="true" [formColumns]="formColumns"
            [formHeight]="formHeight">
          </ngx-activity-watchers>
        </div>
      </div>
    </ng-container>
  </nb-card-body>

  <div *ngIf="((addEditFlag || editViewFlag) && !viewFlag) && sections.length == 0 && !inlineView"
    class="button_show custom-footer">
    <button class="filled-dynamic-color" type="submit" *ngIf="!viewFlag && !editViewFlag && !fromOldView"
      (click)="continueView()" nbButton matRipple>
      Continue &nbsp;<i class="fa fa-chevron-right" aria-hidden="true"></i>
    </button>
    <button class="filled-dynamic-color" type="submit" *ngIf="!viewFlag && !editViewFlag" (click)="submit()" nbButton
      matRipple>
      {{ button.text }}
    </button>
    <button class="outline-dynamic-color" nbButton matRipple *ngIf="!viewFlag && !editViewFlag" (click)="cancel()">
      Cancel
    </button>

    <!-- <button (click)="changeToEditViewMode()" class="outline-dynamic-color" *ngIf="viewFlag && !addEditFlag" nbButton matRipple>
      <i class="fa fa-pencil add_but" aria-hidden="true"></i>
      Edit Details on
    </button> -->
    <button (click)="submit()" class="filled-dynamic-color" *ngIf="editViewFlag" nbButton matRipple>
      Save
    </button>
    <button (click)="backToView()" class="outline-dynamic-color" *ngIf="editViewFlag" nbButton matRipple>
      Cancel
    </button>
  </div>
</nb-card>

<form [formGroup]="dynamicForm">
  <ng-template #formFields let-finalArray="finalArray" let-section="section">
    <ng-container *ngFor="let row of finalArray; let rowIndex = index">
      <ng-container [ngTemplateOutlet]="rowField" [ngTemplateOutletContext]="{
          row: row.data,
          rowIndex: rowIndex,
          rowHide: row.hide
        }">
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template #rowField let-row="row" let-rowIndex="rowIndex" let-rowHide="rowHide">
    <div class="row new-design-row" [ngStyle]="{
        display: rowHide ? 'none' : ''
      }">
      <div *ngFor="let field of row" [ngClass]="[
          !inlineView &&
          !field.isProvideButton &&
          !field.isHidden &&
          field.fieldSize == 'tiny'
            ? 'col-md-3'
            : '',
          !inlineView &&
          !field.isProvideButton &&
          !field.isHidden &&
          field.fieldSize == 'small'
            ? 'col-md-4'
            : '',
          !inlineView &&
          !field.isProvideButton &&
          !field.isHidden &&
          field.fieldSize == 'medium'
            ? 'col-md-6'
            : '',
          !inlineView &&
          !field.isProvideButton &&
          !field.isHidden &&
          field.fieldSize == 'large'
            ? 'col-md-12'
            : '',
          field.isProvideButton ? 'col-md-12' : '',
          this.inlineView ? 'col-md-6' : '',
          field.isProvideButton && field.provideButtonAlignment == 'left'
            ? 'provide-left-btn'
            : '',
          field.isProvideButton && field.provideButtonAlignment == 'right'
            ? 'provide-right-btn'
            : '',
          !show[field.name] ? 'no-content' : '',
          field.type == 'recordType' && !recordTypeFlagFromAddNew
            ? 'no-content'
            : '',
          field.type == 'refButton' && list && list.length ? 'col-md-12' : '',
          field.type == 'refRecords' && refRecordList && refRecordList.length
            ? 'col-md-12'
            : '',
          field.type == 'file' ? 'col-md-12' : '',
          field.type == 'formula' ? 'col-md-12' : '',
          field.type == 'injectSubForm' ? 'col-md-12' : '',
          field.type == 'rollUp' ? 'col-md-12 no-top-space' : '',
          field.type == 'checkList' ? 'col-md-12' : '',
          !field.icon && field.type != 'dropdownWithImage' ? 'without-icon' : ''
        ]">
        <div *ngIf="!field.isProvideButton">
          <ng-container [ngTemplateOutlet]="allFields" [ngTemplateOutletContext]="{ field: field }">
          </ng-container>
        </div>
        <div *ngIf="field.isProvideButton" [ngClass]="{
            'col-md-4':
              field.fieldSize == 'small' && field.showProvideButtonField,
            'col-md-6 ':
              field.fieldSize == 'medium' && field.showProvideButtonField,
            'col-md-12':
              field.fieldSize == 'large' && field.showProvideButtonField,
            'col-md-3':
              field.fieldSize == 'tiny' && field.showProvideButtonField
          }" class="center-div-only no-margin">
          <div style="width: 100%">
            <ng-container *ngIf="
                (this.Data && this.Data[field.name]) ||
                field.showProvideButtonField
              ">
              <ng-container [ngTemplateOutlet]="allFields" [ngTemplateOutletContext]="{ field: field }">
              </ng-container>
            </ng-container>
          </div>

          <ng-container *ngIf="
              (!this.Data && !field.showProvideButtonField) ||
              (this.Data &&
                !this.Data[field.name] &&
                !field.showProvideButtonField &&
                !viewFlag)
            ">
            <div class="provide-btn txt-clr" style="cursor: pointer" (click)="showProvideButtonFieldClick(field)">
              + {{ field.label }}
            </div>
          </ng-container>
        </div>
      </div>

      <div *ngFor="let field of row">
        <ng-container *ngIf="field.isHidden">
          <ng-container [ngTemplateOutlet]="allFields" [ngTemplateOutletContext]="{ field: field }">
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-template>

  <ng-template #allFields let-field="field">
    <ng-container *ngIf="
        field.type == 'rollUp' &&
        field.options &&
        field.options.length &&
        show[field.name]
      ">
      <ng-container *ngIf="editViewFlag || addEditFlag">
        <!-- Label for field type rollUp -->
        <span class="new-label label total-amount-txt" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{
          field.label }}:
          <span *ngIf="dynamicForm.get(field.name).value && field.isCurrency">$</span>
          <span *ngIf="dynamicForm.get(field.name).value && !field.isCurrency"></span>{{
          dynamicForm.get(field.name).value }}
        </span>
      </ng-container>

      <ng-container *ngIf="viewFlag">
        <!-- Label for field type rollUp -->
        <span class="new-label label total-amount-txt" style="text-align: right"
          [ngClass]="{ 'isprovide-label': field.isProvideButton }">
          <div matPrefix class="mat-matPrefix">
            <img *ngIf="field.icon" class="mat-icon notranslate material-icons mat-icon-no-color top04"
              src="{{ field.icon }}" />
          </div>

          {{ field.label }}:

          <!-- View Field for type rollUp -->

          <div class="edit-class-form total-amount-val" *ngIf="Data[field.name] && field.isCurrency"
            [innerHTML]="'$' + Data[field.name]"></div>
          <div class="edit-class-form total-amount-val" *ngIf="Data[field.name] && !field.isCurrency"
            [innerHTML]="Data[field.name]"></div>
        </span>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="
        field.type == 'formula' &&
        field.options &&
        field.options.length &&
        show[field.name]
      ">
      <ng-container *ngIf="editViewFlag || addEditFlag">
        <!-- Label for field type formula -->
        <span class="new-label label total-amount-txt" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{
          field.label }}:
          <span *ngIf="dynamicForm.get(field.name).value && field.isCurrency">$</span>
          <span>{{ dynamicForm.get(field.name).value | CustomNumber }}</span>
          <!-- <span *ngIf="!isNumber(dynamicForm.get(field.name).value)">
            {{ dynamicForm.get(field.name).value }}</span> -->
        </span>

        <!-- Field input for type formula -->
        <ng-container *ngFor="let formula of field.options">
          <!-- For type STRING -->
          <!-- <ng-container *ngIf="formula.type == 'string'">
            <mat-form-field appearance="outline" [ngClass]="[
              field.fieldSize == 'tiny'
                ? 's_s'
                : field.fieldSize == 'small'
                ? 's'
                : field.fieldSize == 'medium'
                ? 'm'
                : 'l',
              dynamicForm.get(field.name).invalid && formSubmitted
                ? 'mat-form-field-invalid'
                : ''
            ]">
              <mat-label>{{ field.label }}</mat-label>
              <input matInput [type]="'text'" [placeholder]="field.label" [formControlName]="field.name"
                [readonly]="field.isReadOnly" [ngClass]="{
                'disable-formula-field': field.isReadOnly
              }" />
              <ngx-help class="help-icon help-right" *ngIf="tutorials | helpPipe: field.label"
                [tutorial]="tutorials | helpPipe: field.label"></ngx-help>
            </mat-form-field>
          </ng-container> -->

          <!-- For type NUMBER -->
          <!-- <ng-container *ngIf="formula.type == 'number'">
            <mat-form-field appearance="outline" [ngClass]="[
              field.fieldSize == 'tiny'
                ? 's_s'
                : field.fieldSize == 'small'
                ? 's'
                : field.fieldSize == 'medium'
                ? 'm'
                : 'l',
              dynamicForm.get(field.name).invalid && formSubmitted
                ? 'mat-form-field-invalid'
                : ''
            ]" class="less-space">
              <mat-label>{{ field.label }}</mat-label>
              <input matInput [type]="'number'" placeholder="{{ field.label }}" [formControlName]="field.name"
                [readonly]="field.isReadOnly" [ngClass]="{
                'disable-formula-field': field.isReadOnly
              }" />
              <ngx-help class="help-icon help-right" *ngIf="tutorials | helpPipe: field.label"
                [tutorial]="tutorials | helpPipe: field.label"></ngx-help>
            </mat-form-field>
          </ng-container> -->

          <!-- For type RADIO -->
          <!-- <ng-container *ngIf="formula.type == 'radio'">
            <div class="checkbox mb-20" [ngClass]="
              field.fieldSize == 'tiny'
                ? 's_s'
                : field.fieldSize == 'small'
                ? 's'
                : field.fieldSize == 'medium'
                ? 'm'
                : 'l'
            ">
              <div *ngIf="field.icon" matPrefix class="mat-matPrefix">
                <img class="
                  mat-icon
                  notranslate
                  material-icons
                  mat-icon-no-color
                  top04
                " src="{{ field.icon }}" />
              </div>
              <nb-radio-group class="radio" name="my-radio-group-{{ field.name }}" [formControlName]="field.name"
                [disabled]="field.isReadOnly">
                <nb-radio value="yes"> Yes </nb-radio>
                <nb-radio value="no"> No </nb-radio>
              </nb-radio-group>
            </div>
          </ng-container> -->

          <!--  For type DATE -->
          <!-- <ng-container *ngIf="formula.type == 'date'">
            <mat-form-field class="mb-20 due-date-col" appearance="outline" [ngClass]="
              field.fieldSize == 'tiny'
                ? 's_s'
                : field.fieldSize == 'small'
                ? 's'
                : field.fieldSize == 'medium'
                ? 'm'
                : 'l'
            ">
              <div *ngIf="field.icon" matPrefix class="mat-matPrefix">
                <img class="
                  mat-icon
                  notranslate
                  material-icons
                  mat-icon-no-color
                  top04
                " src="{{ field.icon }}" />
              </div>
              <input matInput [matDatepicker]="formpicker1" [placeholder]="field.label" [formControlName]="field.name"
                [readonly]="field.isReadOnly" [ngClass]="{
                'disable-formula-field': field.isReadOnly
              }" />
              <mat-datepicker-toggle matSuffix [for]="formpicker1" [disabled]="field.isReadOnly">
              </mat-datepicker-toggle>
              <mat-datepicker #formpicker1></mat-datepicker>
              <ngx-help class="help-icon help-right" *ngIf="tutorials | helpPipe: field.label"
                [tutorial]="tutorials | helpPipe: field.label"></ngx-help>
            </mat-form-field>
          </ng-container> -->
        </ng-container>
      </ng-container>

      <ng-container *ngIf="viewFlag">
        <!-- Label for field type autoNumber -->
        <span class="new-label label total-amount-txt" style="text-align: right"
          [ngClass]="{ 'isprovide-label': field.isProvideButton }">
          <!-- View Field for type autoNumber -->
          <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
            <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
          </div>

          {{ field.label }}:

          <div class="edit-class-form total-amount-val" *ngIf="Data[field.name] && field.isCurrency"
            [innerHTML]="'$' + Data[field.name]"></div>
          <div class="edit-class-form total-amount-val" *ngIf="Data[field.name] && !field.isCurrency"
            [innerHTML]="Data[field.name]"></div>
        </span>
      </ng-container>
    </ng-container>

    <!-- Field input for type injectSubForm -->
    <ng-container *ngIf="field.type == 'injectSubForm' && field.isPopOut">
      <!-- <ng-container *ngIf="showSubForm"> -->
      <nb-card class="sub-form-card">
        <nb-card-body>
          <div class="sub-form-divider" *ngFor="
              let subForm of subFormTableName
                | keyvalue
                | subFormFilter : formColumns : field.name;
              let idx = index
            ">
            <ng-container *ngIf="
                showSubForm[subForm.key] &&
                (viewFlagForSubForm[subForm.key] || showEditForSubForm)
              ">
              <ngx-dynamic-subform [unwantedFieldsInOptions]="unwantedFieldsInOptions" [editFormData]="tempEditFormData"
                [subFormLookupName]="subForm.key" (subFormData)="getSubFormFields($event)" [recordType]="recordType"
                (subFormBuilder)="getSubFormValue($event)" [subFormFields]="subFormFields[subForm.value]"
                [subFormName]="subForm.value" [tutorials]="tutorials" [lookUpNameId]="lookUpNameId"
                [parentTableName]="parentTableName" (subFormLookupData)="getSubFormLookupData($event)"
                [formSubmitted]="formSubmitted" [tableDataForForms]="tableDataForForms" [viewFlagForSubForm]="
                  viewFlagForSubForm[subForm.key] || showEditForSubForm
                " [isPopOut]="field.isPopOut" [tableId]="tableId" [addEditMode]="addEditFlag">
              </ngx-dynamic-subform>
              <a class="txt-clr edit-anchor-icon">
                <span *ngIf="
                    showSubForm[subForm.key] &&
                    (viewFlagForSubForm[subForm.key] || showEditForSubForm)
                  " (click)="showSubFormToggle(subForm, field, true)" class="mat-custom material-icons-outlined"
                  nbTooltip="Edit SubForm Record" nbTooltipPlacement="top" nbTooltipStatus="basic"
                  nbTooltipTrigger="hover">
                  edit
                </span>
              </a>
              <!-- <nb-icon style="width: 30px;height: 30px;" (click)="showSubFormToggle(subForm,field,true)"
                *ngIf="showSubForm[subForm.key] && (viewFlagForSubForm[subForm.key] || showEditForSubForm)"
                icon="edit-outline"></nb-icon> -->
            </ng-container>

            <ng-container *ngIf="!showSubForm[subForm.key]">
              <button (click)="showSubFormToggle(subForm, field)" nbButton matRipple
                class="btn btn-primary filled-dynamic-color">
                + Add {{ subForm.value }}
              </button>
            </ng-container>
          </div>
        </nb-card-body>
      </nb-card>
      <!-- </ng-container> -->

      <!-- <ng-container [ngTemplateOutlet]="subFormTemplate"></ng-container> -->
    </ng-container>

    <ng-container *ngIf="field.type == 'injectSubForm' && !field.isPopOut">
      <!-- <ng-container *ngIf="showSubForm"> -->
      <nb-card class="sub-form-card">
        <nb-card-body>
          <div class="sub-form-divider" *ngFor="
              let subForm of subFormTableName
                | keyvalue
                | subFormFilter : formColumns : field.name;
              let idx = index
            ">
            <ng-container *ngIf="showSubForm[subForm.key]">
              <ngx-dynamic-subform [unwantedFieldsInOptions]="unwantedFieldsInOptions" [editFormData]="tempEditFormData"
                [subFormLookupName]="subForm.key" (subFormData)="getSubFormFields($event)" [recordType]="recordType"
                (subFormBuilder)="getSubFormValue($event)" [subFormFields]="subFormFields[subForm.value]"
                [subFormName]="subForm.value" [lookUpNameId]="lookUpNameId" [parentTableName]="parentTableName"
                (subFormLookupData)="getSubFormLookupData($event)" [formSubmitted]="formSubmitted"
                [tableDataForForms]="tableDataForForms" [viewFlagForSubForm]="viewFlagForSubForm[subForm.key]"
                [tableId]="tableId" [addEditMode]="addEditFlag">
              </ngx-dynamic-subform>
            </ng-container>

            <ng-container *ngIf="!showSubForm[subForm.key]">
              <button (click)="showSubFormToggle(subForm, field)" nbButton matRipple
                class="btn btn-primary filled-dynamic-color">
                + Add {{ subForm.value }}
              </button>
            </ng-container>
          </div>
        </nb-card-body>
      </nb-card>
      <!-- </ng-container> -->

      <!-- <ng-container [ngTemplateOutlet]="subFormTemplate"></ng-container> -->
    </ng-container>

    <ng-container *ngIf="field.name == 'zip'">
      <ng-container *ngIf="(addEditFlag || editViewFlag) && field.showAsInput">
        <!-- Label for field type zip -->
        <div class="d-flex with-hlp-ic">
          <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:
            <span *ngIf="field.isRequired" style="color: #f44336">*</span>
            <ngx-help *ngIf="tutorials | helpPipe : field.label" [tutorial]="tutorials | helpPipe : field.label">
            </ngx-help>
          </span>
        </div>

        <!-- Field input for type zip -->
        <mat-form-field appearance="outline" *ngIf="field.name == 'zip'" [ngClass]="[
            field.fieldSize == 'tiny'
              ? 's_s'
              : field.fieldSize == 'small'
              ? 's'
              : field.fieldSize == 'medium'
              ? 'm'
              : 'l',
            dynamicForm.get(field.name).invalid && formSubmitted
              ? 'mat-form-field-invalid'
              : ''
          ]" appearance="outline">
          <mat-label>{{ field.label }}</mat-label>

          <input matInput [type]="field.type" (keyup)="listCity(dynamicForm)" [formControlName]="field.name" [readonly]="
              field.isReadOnly ||
              (field.onlyEditInAdd ? (id ? true : false) : false)
            " />
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="viewFlag || !field.showAsInput">
        <!-- Label for field type zip -->
        <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:</span>

        <!-- View Field for type zip -->
        <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
          <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
        </div>
        <div class="edit-class-form" *ngIf="dynamicForm.get(field.name).value"
          [innerHTML]="dynamicForm.get(field.name).value"></div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="field.name != 'zip'">
      <ng-container *ngIf="
          inputFields.includes(field.type) &&
          show[field.name] &&
          !field.isHidden
        ">
        <ng-container *ngIf="(addEditFlag || editViewFlag) && field.showAsInput">
          <!-- Label for field type of inputFields -->
          <div class="d-flex with-hlp-ic">
            <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:
              <span *ngIf="field.isRequired" style="color: #f44336">*</span>
              <ngx-help *ngIf="tutorials | helpPipe : field.label" [tutorial]="tutorials | helpPipe : field.label">
              </ngx-help>
            </span>
          </div>

          <!-- Field input for type of inputFields -->
          <mat-form-field class="phone-number 22" class="mb-20" appearance="outline" [ngClass]="[
              field.fieldSize == 'tiny'
                ? 's_s'
                : field.fieldSize == 'small'
                ? 's'
                : field.fieldSize == 'medium'
                ? 'm'
                : 'l',
              dynamicForm.get(field.name).invalid && formSubmitted
                ? 'mat-form-field-invalid'
                : '',
              field.icon ? 'with-icon' : '',
              field.isReadOnly ? 'is-read-only' : ''
            ]" [floatLabel]="'never'">
            <mat-label>{{ field.label }}</mat-label>

            <div *ngIf="field.icon" matPrefix class="mat-matPrefix">
              <img class="mat-icon notranslate material-icons mat-icon-no-color" src="{{ field.icon }}" />
            </div>
            <input *ngIf="!field.isPhone" matInput placeholder="{{ field.label }}" [type]="field.type"
              [formControlName]="field.name" (keyup)="evalExpressionForFormulaField()" [readonly]="
                field.isReadOnly ||
                (field.onlyEditInAdd ? (id ? true : false) : false)
              " />
            <input *ngIf="field.isPhone" #item [ngModel]="dynamicForm.get(field.name).value | phone" ngxNumberOnly
              matInput placeholder="{{ field.label }}" [type]="field.type" [formControlName]="field.name" [readonly]="
                field.isReadOnly ||
                (field.onlyEditInAdd ? (id ? true : false) : false)
              " />
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="viewFlag || !field.showAsInput">
          <!-- Label for field type inputFields -->
          <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label
            }}:</span>

          <!-- View Field for type inputFields -->
          <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
            <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
          </div>
          <div class="edit-class-form" *ngIf="field.name !== 'phoneNumber' && dynamicForm.get(field.name).value">
            <!-- <a class="value_text" href="mailto:{{Data[field.name]}}"
              *ngIf="matchPattern(Data[field.name]) && !field.isPhone">{{Data[field.name] | linkify}}</a> -->
            <div *ngIf="
                field.linkify && !field.isPhone && dynamicForm.get(field.name).value
              " [innerHTML]="dynamicForm.get(field.name).value | linkify | renderImage"></div>
            <div *ngIf="
                !field.linkify && !field.isPhone && dynamicForm.get(field.name).value
              " [innerHTML]="dynamicForm.get(field.name).value | renderImage"></div>
            <div *ngIf="
                !field.linkify && field.isPhone && dynamicForm.get(field.name).value
              ">
              {{ dynamicForm.get(field.name).value | phone }}
            </div>
          </div>
          <div class="edit-class-form" *ngIf="field.name == 'phoneNumber' && dynamicForm.get(field.name).value">
            {{ dynamicForm.get(field.name).value | phone }}
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="
          (field.type == 'number' || field.type == 'currency') &&
          show[field.name] &&
          !field.isHidden
        ">
        <ng-container *ngIf="(addEditFlag || editViewFlag) && field.showAsInput">
          <!-- Label for field type number -->
          <div class="d-flex with-hlp-ic">
            <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:
              <span *ngIf="field.isRequired" style="color: #f44336">*</span>
              <ngx-help *ngIf="tutorials | helpPipe : field.label" [tutorial]="tutorials | helpPipe : field.label">
              </ngx-help>
            </span>
          </div>

          <!-- Field input for type of number -->
          <mat-form-field class="mb-20 unit-amount less-space 33" appearance="outline" [ngClass]="[
              field.fieldSize == 'tiny'
                ? 's_s'
                : field.fieldSize == 'small'
                ? 's'
                : field.fieldSize == 'medium'
                ? 'm'
                : 'l',
              dynamicForm.get(field.name).invalid && formSubmitted
                ? 'mat-form-field-invalid'
                : '',
              field.icon ? 'with-icon' : '',
              field.isReadOnly ? 'is-read-only' : ''
            ]">
            <mat-label>{{ field.label }}</mat-label>

            <div matPrefix *ngIf="field.isCurrency">
              <mat-icon class="money_icon">attach_money</mat-icon>
            </div>
            <input *ngIf="!field.isPhone" matInput placeholder="{{ field.label }}" type="number"
              [placeholder]="field.label" [formControlName]="field.name" [readonly]="
                field.isReadOnly ||
                (field.onlyEditInAdd ? (id ? true : false) : false)
              " (keyup)="evalExpressionForFormulaField()" (change)="evalExpressionForFormulaField()" />
            <input *ngIf="field.isPhone" #item [value]="dynamicForm.get(field.name).value | phone" ngxNumberOnly
              matInput (keyup)="evalExpressionForFormulaField()" (change)="evalExpressionForFormulaField()"
              placeholder="{{ field.label }}" [type]="field.type" [placeholder]="field.label"
              [formControlName]="field.name" [readonly]="
                field.isReadOnly ||
                (field.onlyEditInAdd ? (id ? true : false) : false)
              " />
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="viewFlag || !field.showAsInput">
          <!-- Label for field type number -->
          <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label
            }}:</span>

          <!-- View Field for type number -->
          <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
            <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
          </div>
          <div class="edit-class-form" *ngIf="dynamicForm.get(field.name).value" [innerHTML]="
              field.isCurrency
                ? (dynamicForm.get(field.name).value | currency)
                : dynamicForm.get(field.name).value
            "></div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="field.type == 'checkList' && show[field.name] && !field.isHidden">
        <ng-container>
          <div class="to-do-list-field">
            <!-- Field input for type of number -->

            <ng-container *ngIf="checkListArray && checkListArray.length">
              <ng-container *ngFor="let parent of checkListArray; let i = index">
                <div *ngIf="showList[i]" class="checklist-row-data">
                  <span (click)="removeWholeCheckList(i)"
                    class="material-icons pull-right cursor-pointer mat-custom close-new-form pos-abs txt-clr"
                    [style.color]="this.systemConfigColor" nbTooltip="Delete checklist" nbTooltipPlacement="top"
                    nbTooltipStatus="basic" nbTooltipTrigger="hover">
                    close
                  </span>
                  <h4>{{ parent.name }}</h4>

                  <span class="add-new-item-checklist" (click)="addItemInsideCheck(i)">+ Add New Item</span>
                  <div *ngFor="let item of parent.value; let ind = index" id="{{ item }}-{{ ind }}"
                    class="checklist-row" style="display: flex">
                    <nb-checkbox [checked]="item.checked" (change)="onCheckedItem($event, item, ind, i)"
                      class="inner_checkbox">
                      {{ item.item }}
                    </nb-checkbox>

                    <input *ngIf="showItemInput[i][ind]" type="text" (keyup)="bindItemOfCheckList($event, i, ind)"
                      class="form-control border-clr" placeholder="Enter item name" />

                    <span (click)="removeItemFromCheckList(i, ind)"
                      class="material-icons pull-right mat-custom cursor-pointer close-new-form txt-clr"
                      [style.color]="this.systemConfigColor" nbTooltip="Remove item" nbTooltipPlacement="top"
                      nbTooltipStatus="basic" nbTooltipTrigger="hover">
                      close
                    </span>
                  </div>
                </div>

                <div class="pos-rel-new">
                  <input *ngIf="showNameInput[i]" type="text" (keyup)="bindNameOfCheckList($event, i)"
                    class="form-control checklist-input border-clr" placeholder="Enter checklist name" />
                  <span id="cross-{{ i }}" *ngIf="showNameInput[i]" (click)="hideNameInput(i)"
                    class="material-icons pull-right cursor-pointer mat-custom close-new-form pos-abs txt-clr"
                    [style.color]="this.systemConfigColor">
                    close
                  </span>
                </div>
              </ng-container>
            </ng-container>

            <button style="margin-top: 17px" (click)="addNewCheckList()" nbButton matRipple
              class="btn provide-btn txt-clr">
              + Add {{ field.label }}
            </button>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="field.type == 'area' && show[field.name] && !field.isHidden">
        <ng-container *ngIf="(addEditFlag || editViewFlag) && field.showAsInput">
          <!-- Label for field type area -->
          <div class="d-flex with-hlp-ic">
            <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:
              <span *ngIf="field.isRequired" style="color: #f44336">*</span>
              <ngx-help *ngIf="tutorials | helpPipe : field.label" [tutorial]="tutorials | helpPipe : field.label">
              </ngx-help>
            </span>
          </div>

          <!-- Field input for type of area -->
          <mat-form-field class="mb-20 reminder-note textarea-hght-auto" appearance="outline" [ngClass]="[
              field.fieldSize == 'tiny'
                ? 's_s'
                : field.fieldSize == 'small'
                ? 's'
                : field.fieldSize == 'medium'
                ? 'm'
                : 'l',
              dynamicForm.get(field.name).invalid && formSubmitted
                ? 'mat-form-field-invalid'
                : '',
              field.icon ? 'with-icon' : '',
              field.isReadOnly ? 'is-read-only' : ''
            ]">
            <mat-label>{{ field.label }}</mat-label>

            <textarea [rows]="field.textAreaLines" matInput [placeholder]="field.label" [formControlName]="field.name"
              [readonly]="
                field.isReadOnly ||
                (field.onlyEditInAdd ? (id ? true : false) : false)
              " (keyup)="evalExpressionForFormulaField()"></textarea>
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="viewFlag || !field.showAsInput">
          <!-- Label for field type area -->
          <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label
            }}:</span>

          <!-- View Field for type area -->
          <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
            <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
          </div>

          <div class="edit-class-form textarea-val" *ngIf="field.type == 'area' && dynamicForm.get(field.name).value"
            [innerHTML]="dynamicForm.get(field.name).value | linkify"></div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="field.type === 'dropdown' && show[field.name] && !field.isHidden">
        <ng-container *ngIf="(addEditFlag || editViewFlag) && field.showAsInput">
          <!-- Label for field type dropdown -->
          <div class="d-flex with-hlp-ic">
            <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:
              <span *ngIf="field.isRequired" style="color: #f44336">*</span>
              <ngx-help *ngIf="tutorials | helpPipe : field.label" [tutorial]="tutorials | helpPipe : field.label">
              </ngx-help>
            </span>
          </div>

          <!-- Field input for type of dropdown -->
          <mat-form-field class="mb-20" appearance="outline" [ngClass]="[
              field.fieldSize == 'tiny'
                ? 's_s'
                : field.fieldSize == 'small'
                ? 's'
                : field.fieldSize == 'medium'
                ? 'm'
                : 'l',
              dynamicForm.get(field.name).invalid && formSubmitted
                ? 'mat-form-field-invalid'
                : '',
              field.icon ? 'with-icon' : '',
              field.isReadOnly ? 'is-read-only' : ''
            ]">
            <mat-label>{{ field.label }}</mat-label>

            <div *ngIf="field.icon" matPrefix class="mat-matPrefix">
              <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
            </div>
            <ng-container *ngIf="!field.allowMultipleValues">
              <mat-select [formControlName]="field.name" [disabled]="
                  field.isReadOnly ||
                  (field.onlyEditInAdd ? (id ? true : false) : false)
                " (selectionChange)="
                  onDependentFieldChanged(
                    $event,
                    field._id,
                    field.type,
                    field.name,
                    section,
                    rowIndex
                  )
                ">
                <mat-option *ngFor="let option of field.options" [value]="option" matRipple>
                  {{ option }}
                </mat-option>
              </mat-select>
            </ng-container>
            <ng-container *ngIf="field.allowMultipleValues">
              <mat-select [formControlName]="field.name" multiple [placeholder]="field.label" [disabled]="
                  field.isReadOnly ||
                  (field.onlyEditInAdd ? (id ? true : false) : false)
                " (change)="evalExpressionForFormulaField()" (selectionChange)="
                  onDependentFieldChanged(
                    $event,
                    field._id,
                    field.type,
                    field.name,
                    section,
                    rowIndex
                  )
                ">
                <mat-option *ngFor="let option of field.options" [value]="option" matRipple>
                  {{ option }}
                </mat-option>
              </mat-select>
            </ng-container>
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="viewFlag || !field.showAsInput">
          <!-- Label for field type dropdown -->
          <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label
            }}:</span>

          <!-- View Field for type dropdown -->
          <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
            <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
          </div>
          <div class="edit-class-form textarea-val" *ngIf="dynamicForm.get(field.name).value"
            [innerHTML]="dynamicForm.get(field.name).value">
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="field.type === 'gadget' && show[field.name] && !field.isHidden">
        <ng-container *ngIf="addEditFlag || editViewFlag">
          <!-- Label for field type gadget -->
          <div class="d-flex with-hlp-ic">
            <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:
              <span *ngIf="field.isRequired" style="color: #f44336">*</span>
              <ngx-help *ngIf="tutorials | helpPipe : field.label" [tutorial]="tutorials | helpPipe : field.label">
              </ngx-help>
            </span>
          </div>

          <!-- Field input for type of gadget -->
          <mat-form-field appearance="outline" [ngClass]="[
              field.fieldSize == 'tiny'
                ? 's_s'
                : field.fieldSize == 'small'
                ? 's'
                : field.fieldSize == 'medium'
                ? 'm'
                : 'l',
              dynamicForm.get(field.name).invalid && formSubmitted
                ? 'mat-form-field-invalid'
                : ''
            ]">
            <mat-label>{{ field.label }}</mat-label>

            <input matInput [type]="'text'" [placeholder]="field.label" [formControlName]="field.name" readonly
              [ngClass]="{ 'disable-formula-field': field.isReadOnly }"
              [disabled]="field.onlyEditInAdd ? (id ? true : false) : false" [(ngModel)]="currentGadgetValue" />
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="viewFlag">
          <!-- Label for field type gadget -->
          <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label
            }}:</span>

          <!-- View Field for type gadget -->
          <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
            <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
          </div>
          <div class="edit-class-form" *ngIf="gadgetFieldValue" [innerHTML]="gadgetFieldValue"></div>
        </ng-container>
      </ng-container>

      <ng-container class="fltt-left mb-20" *ngIf="
          field.type === 'dropdownWithImage' &&
          show[field.name] &&
          !field.isHidden
        ">
        <ng-container *ngIf="(addEditFlag || editViewFlag) && field.showAsInput">
          <!-- Label for field type dropdownWithImage -->
          <div class="d-flex with-hlp-ic">
            <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:
              <span *ngIf="field.isRequired" style="color: #f44336">*</span>
              <ngx-help *ngIf="tutorials | helpPipe : field.label" [tutorial]="tutorials | helpPipe : field.label">
              </ngx-help>
            </span>
          </div>

          <!-- Field input for type of dropdownWithImage -->
          <ng-container *ngIf="!field.allowMultipleValues">
            <div class="select-new" [ngClass]="{
                l: field.fieldSize == 'large',
                s_s: field.fieldSize == 'tiny',
                s: field.fieldSize == 'small',
                m: field.fieldSize == 'medium',
                'mat-form-field-invalid':
                  dynamicForm.get(field.name).invalid && formSubmitted
              }">
              <div *ngIf="field.icon" matPrefix class="mat-matPrefix">
                <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
              </div>
              <mat-label>{{ field.label }}</mat-label>

              <ng-select [readonly]="
                  field.isReadOnly ||
                  (field.onlyEditInAdd ? (id ? true : false) : false)
                " [formControlName]="field.name" [ngClass]="{
                  'mat-form-field-invalid':
                    dynamicForm.get(field.name).invalid && formSubmitted
                }" appendTo="#{{ field.name }}" [id]="field.name" [clearable]="false" placeholder="{{ field.label }}"
                (change)="
                  onDependentFieldChanged(
                    $event,
                    field._id,
                    field.type,
                    field.name,
                    section,
                    rowIndex
                  )
                " class="ng-select-latest single-select">
                <ng-option *ngFor="let val of field.options" [value]="val.title">
                  <div class="drop-down-img-col">
                    <img [src]="val.image" alt="" height="18" width="18" />
                    <span class="select-text">{{ val.title }}</span>
                  </div>
                </ng-option>
              </ng-select>
            </div>
          </ng-container>

          <ng-container *ngIf="field.allowMultipleValues" class="mat-form-field drop-down-img-col">
            <div class="select-new" [ngClass]="{
                l: field.fieldSize == 'large',
                s_s: field.fieldSize == 'tiny',
                s: field.fieldSize == 'small',
                m: field.fieldSize == 'medium',
                'mat-form-field-invalid':
                  dynamicForm.get(field.name).invalid && formSubmitted
              }">
              <mat-label>{{ field.label }}</mat-label>

              <ng-select [readonly]="
                  field.isReadOnly ||
                  (field.onlyEditInAdd ? (id ? true : false) : false)
                " [multiple]="true" [formControlName]="field.name" appendTo="#{{ field.name }}" [id]="field.name"
                placeholder="{{ field.label }}" (change)="
                  onDependentFieldChanged(
                    $event,
                    field._id,
                    field.type,
                    field.name,
                    section,
                    rowIndex
                  )
                " class="ng-select-latest">
                <ng-option *ngFor="let val of field.options" [value]="val.title">
                  <div class="col-md-12 drop-down-img-col">
                    <img [src]="val.image" alt="" height="18" width="18" />
                    <span>{{ val.title }}</span>
                  </div>
                </ng-option>
              </ng-select>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="viewFlag || !field.showAsInput">
          <!-- Label for field type dropdownWithImage -->
          <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label
            }}:</span>

          <!-- View Field for type dropdownWithImage -->
          <div matPrefix class="mat-matPrefix">
            <img *ngIf="field.type == 'dropdownWithImage'"
              class="mat-icon notranslate material-icons mat-icon-no-color top04"
              src="{{ imageForDropdownWithImageView }}" />
          </div>
          <div class="edit-class-form textarea-val" *ngIf="dynamicForm.get(field.name).value"
            [innerHTML]="dynamicForm.get(field.name).value">
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="field.type == 'date' && show[field.name] && !field.isHidden">
        <ng-container *ngIf="(addEditFlag || editViewFlag) && field.showAsInput">
          <!-- Label for field type date -->
          <div class="d-flex with-hlp-ic">
            <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:
              <span *ngIf="field.isRequired" style="color: #f44336">*</span>
              <ngx-help *ngIf="tutorials | helpPipe : field.label" [tutorial]="tutorials | helpPipe : field.label">
              </ngx-help>
            </span>
          </div>

          <!-- Field input for type of date -->
          <mat-form-field class="mb-20 due-date-col mat-dt-picker-modal" appearance="outline" [ngClass]="[
              field.fieldSize == 'tiny'
                ? 's_s'
                : field.fieldSize == 'small'
                ? 's'
                : field.fieldSize == 'medium'
                ? 'm'
                : 'l',
              dateErrorFlag && formSubmitted ? 'mat-form-field-invalid' : '',
              field.icon ? 'with-icon' : '',
              field.isReadOnly ? 'is-read-only' : ''
            ]">
            <div *ngIf="field.icon" matPrefix class="mat-matPrefix">
              <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
            </div>
            <input matInput [matDatepicker]="formpicker" [placeholder]="" [formControlName]="field.name" [readonly]="
                field.isReadOnly ||
                (field.onlyEditInAdd ? (id ? true : false) : false)
              " [ngModelOptions]="{ timezone: 'EST' }" (dateChange)="evalExpressionForFormulaField()"
              (dateInput)="evalExpressionForFormulaField()" />
            <mat-datepicker-toggle matSuffix [for]="formpicker">
            </mat-datepicker-toggle>
            <mat-datepicker #formpicker></mat-datepicker>
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="viewFlag || !field.showAsInput">
          <!-- Label for field type date -->
          <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label
            }}:</span>

          <!-- View Field for type date -->
          <img *ngIf="field.icon" class="mat-icon notranslate material-icons mat-icon-no-color top04"
            src="{{ field.icon }}" />

          <div class="edit-class-form" *ngIf="field.type == 'date' || field.type == 'dateTime'">
            {{
            (Data
            ? Data[field.name]
            ? Data[field.name]
            : dynamicForm.get(field.name).value
            : dynamicForm.get(field.name).value
            ) | dateCustomPipe : "EST"
            }}
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="field.type == 'dateTime' && show[field.name] && !field.isHidden">
        <ng-container *ngIf="(addEditFlag || editViewFlag) && field.showAsInput">
          <!-- Label for field type dateTime -->
          <div class="d-flex with-hlp-ic">
            <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:
              <span *ngIf="field.isRequired" style="color: #f44336">*</span>
              <ngx-help *ngIf="tutorials | helpPipe : field.label" [tutorial]="tutorials | helpPipe : field.label">
              </ngx-help>
            </span>
          </div>

          <!-- Field input for type of dateTime -->
          <div class="date-time-pic-cus" [ngClass]="[
              field.fieldSize == 'tiny'
                ? 's_s'
                : field.fieldSize == 'small'
                ? 's'
                : field.fieldSize == 'medium'
                ? 'm'
                : 'l',
              dateTimeErrorFlag && formSubmitted
                ? 'mat-form-field-invalid'
                : '',
              field.isReadOnly ? 'is-read-only' : ''
            ]">
            <div *ngIf="field.icon" matPrefix class="mat-matPrefix">
              <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
            </div>
            <span class="date-time-pic-cus-title" [ngClass]="{ 'title-active': dateTimePickerToggeledOn }">{{
              field.label }} :</span>
            <app-date-time-picker [field]="field" [disabled]="
                field.isReadOnly ||
                (field.onlyEditInAdd ? (id ? true : false) : false)
              " [formControlName]="field.name" pla (ngModelChange)="evalExpressionForFormulaField()">
            </app-date-time-picker>
          </div>
        </ng-container>

        <ng-container *ngIf="viewFlag || !field.showAsInput">
          <!-- Label for field type dateTime -->
          <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label
            }}:</span>

          <!-- View Field for type dateTime -->
          <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
            <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
          </div>
          <div class="edit-class-form" *ngIf="
              (field.type == 'date' || field.type == 'dateTime') &&
              Data &&
              Data[field.name]
            ">
            {{
            (Data
            ? Data[field.name]
            ? Data[field.name]
            : dynamicForm.get(field.name).value
            : dynamicForm.get(field.name).value
            ) | dateCustomPipe : "EST"
            }}
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="field.type == 'status' && show[field.name] && !field.isHidden">
        <!-- <ng-container *ngIf="viewFlag || !field.showAsInput"> -->
        <!-- Label for field type status -->
        <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:
          <span *ngIf="field.isRequired" style="color: #f44336">*</span>
        </span>

        <!-- Field input for type of status -->
        <div class="status-buttonss-form possition-relative" [ngClass]="{
            l: field.fieldSize == 'large',
            s_s: field.fieldSize == 'tiny',
            s: field.fieldSize == 'small',
            m: field.fieldSize == 'medium'
          }">
          <mat-form-field appearance="outline" [ngClass]="[
              dynamicForm.get(field.name).invalid && formSubmitted
                ? 'mat-form-field-invalid'
                : '',
              field.icon ? 'with-icon' : '',
              field.isReadOnly ? 'is-read-only' : ''
            ]">
            <div class="status-btn-flex">
              <mat-label [title]="statuses[field.name]">
                <span class="status-color-bar txt-clr" [ngStyle]="{ background: colorSetter[field.name] }"></span>

                <i class="fas fa-circle mr-1" [ngStyle]="{ color: colorSetter[field.name] }"></i>
                <span class="status-label-text">
                  {{
                  statuses[field.name] ? statuses[field.name] : field.label
                  }}
                </span>
              </mat-label>

              <mat-select [disabled]="
                  field.isReadOnly ||
                  (field.onlyEditInAdd ? (id ? true : false) : false)
                " [ngStyle]="{ display: field.isReadOnly ? 'none' : '' }" [formControlName]="field.name">
                <div class="px-3">
                  <mat-form-field>
                    <input matInput #statusSearch placeholder="Search" (click)="$event.stopPropagation()" />
                  </mat-form-field>
                </div>
                <mat-option [class.mat-active]="status.status === statuses[field.name]" *ngFor="
                    let status of field.statusOptions
                      | searchStatusOptions : statusSearch.value
                  " [value]="status" (click)="onDone(status, field, rowIndex, section)">
                  <i class="fas fa-circle" [ngStyle]="{ color: status.color }"></i>
                  {{ status.status }}
                </mat-option>
              </mat-select>
            </div>
          </mat-form-field>
        </div>
        <!-- </ng-container> -->
      </ng-container>

      <ng-container *ngIf="field.type == 'checkbox' && show[field.name] && !field.isHidden">
        <ng-container *ngIf="(addEditFlag || editViewFlag) && field.showAsInput">
          <!-- Label for field type checkbox -->
          <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:
            <span *ngIf="field.isRequired" style="color: #f44336">*</span>
          </span>

          <!-- Field input for type of checkbox -->
          <div class="checkbox mb-20" [ngClass]="{
              'is-invalid':
                field.checkBoxValidateForDynamicForm && formSubmitted
            }">
            <ng-container [formArrayName]="field.name" *ngFor="let item of field.options; let i = index">
              <nb-checkbox (change)="
                  onDependentFieldChanged(
                    $event,
                    field._id,
                    field.type,
                    field.name,
                    section,
                    rowIndex,
                    field
                  )
                " class="inner_checkbox" [formControlName]="i" [disabled]="
                  field.isReadOnly ||
                  (field.onlyEditInAdd ? (id ? true : false) : false)
                " [checked]="field">
                {{ item }}
              </nb-checkbox>
            </ng-container>
          </div>
        </ng-container>
        <ng-container *ngIf="viewFlag || !field.showAsInput">
          <!-- Label for field type checkbox -->
          <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label
            }}:</span>

          <!-- View Field for type checkbox -->
          <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
            <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
          </div>
          <div class="edit-class-form" *ngIf="dynamicForm.get(field.name).value"
            [innerHTML]="dynamicForm.get(field.name).value"></div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="
          field.type == 'recordType' &&
          show[field.name] &&
          !field.isHidden &&
          recordTypeFlagFromAddNew
        ">
        <ng-container *ngIf="(addEditFlag || editViewFlag) && field.showAsInput">
          <!-- Label for field type recordType -->
          <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:
            <span *ngIf="field.isRequired" style="color: #f44336">*</span>
          </span>

          <!-- Field input for type of recordType -->
          <div class="status-buttonss-form possition-relative" [ngClass]="{
              l: field.fieldSize == 'large',
              s_s: field.fieldSize == 'tiny',
              s: field.fieldSize == 'small',
              m: field.fieldSize == 'medium'
            }">
            <mat-form-field appearance="outline">
              <mat-label>{{ refRecordType }} Type </mat-label>

              <div *ngIf="field.icon" matPrefix class="mat-matPrefix">
                <img class="mat-icon notranslate material-icons mat-icon-no-color" src="field.icon" />
              </div>

              <mat-select (selectionChange)="
                  onDependentFieldChanged(
                    $event,
                    field._id,
                    field.type,
                    field.name,
                    section,
                    rowIndex
                  )
                " [placeholder]="refRecordType" [formControlName]="field.name">
                <mat-option *ngFor="let option of optionRecordType" matRipple [value]="option.title">
                  {{ option.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </ng-container>
        <ng-container *ngIf="viewFlag || !field.showAsInput">
          <!-- Label for field type recordType -->
          <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label
            }}:</span>

          <!-- View Field for type recordType -->
          <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
            <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
          </div>
          <div class="edit-class-form" *ngIf="dynamicForm.get(field.name).value"
            [innerHTML]="dynamicForm.get(field.name).value"></div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="field.type == 'radio' && show[field.name] && !field.isHidden">
        <ng-container *ngIf="(addEditFlag || editViewFlag) && field.showAsInput">
          <!-- Label for field type recordType -->
          <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:
            <span *ngIf="field.isRequired" style="color: #f44336">*</span>
          </span>

          <!-- Field input for type of recordType -->
          <div class="checkbox mb-20 check-radio" [ngClass]="{
              'is-invalid':
                dynamicForm.get(field.name).invalid && formSubmitted,
              l: field.fieldSize == 'large',
              s_s: field.fieldSize == 'tiny',
              s: field.fieldSize == 'small',
              m: field.fieldSize == 'medium'
            }">
            <nb-radio-group class="radio" name="my-radio-group-{{ field.name }}" [formControlName]="field.name"
              [disabled]="
                field.isReadOnly ||
                (field.onlyEditInAdd ? (id ? true : false) : false)
              ">
              <nb-radio (valueChange)="
                  onDependentFieldChanged(
                    $event,
                    field._id,
                    field.type,
                    field.name,
                    section,
                    rowIndex
                  )
                " *ngFor="let item of field.options" [value]="item">
                {{ item }}
              </nb-radio>
            </nb-radio-group>
          </div>
        </ng-container>

        <ng-container *ngIf="viewFlag || !field.showAsInput">
          <!-- Label for field type recordType -->
          <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label
            }}:</span>

          <!-- View Field for type recordType -->
          <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
            <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
          </div>
          <div class="edit-class-form" *ngIf="dynamicForm.get(field.name).value"
            [innerHTML]="dynamicForm.get(field.name).value"></div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="
          field.type == 'richTextArea' &&
          show[field.name] &&
          loadTinyMce == true &&
          !field.isHidden
        ">
        <ng-container *ngIf="(addEditFlag || editViewFlag) && field.showAsInput">
          <!-- Label for field type recordType -->
          <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:
            <span *ngIf="field.isRequired" style="color: #f44336">*</span>
          </span>
          <!-- Field input for type of recordType -->
      <mat-form-field class="mb-20 l text-area-con  reminder-note textarea-hght-auto" appearance="outline" >
        <mat-label>{{ field.label }}</mat-label>


        <textarea  matInput [placeholder]="field.label" [formControlName]="field.name"
        [readonly]="
          field.isReadOnly ||
          (field.onlyEditInAdd ? (id ? true : false) : false)
        " (keyup)="evalExpressionForFormulaField()"></textarea>

      </mat-form-field>

          <!-- <div class="l text-area-con mb-20">
            <div class="d-flex with-hlp-ic">
              <span class="position-relative with-help">{{ field.label }}&nbsp; &nbsp;:
              </span>
              <ngx-help *ngIf="tutorials | helpPipe : field.label" [tutorial]="tutorials | helpPipe : field.label">
              </ngx-help>
            </div>


            <editor [init]="{
                plugins: 'autoresize',
                toolbar: false,
                skin_url: 'assets/skins/lightgray'
              }" (ngModelChange)="evalExpressionForFormulaField()"
              apiKey="3zk4lcdxv42ueqfotvbtw7lihq6uwkb0cy7l33muhxhr778z" [formControlName]="field.name">
            </editor>
          </div> -->
        </ng-container>

        <ng-container *ngIf="viewFlag || !field.showAsInput">
          <!-- Label for field type recordType -->
          <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label
            }}:</span>

          <!-- View Field for type recordType -->
          <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
            <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
          </div>

          <div class="edit-class-form" *ngIf="dynamicForm.get(field.name).value"
            [innerHTML]="dynamicForm.get(field.name).value | inlineHtml">
          </div>
        </ng-container>
      </ng-container>

      <ng-container class="fltt-left mb-20" *ngIf="
          field.type == 'lookup' &&
          show[field.name] &&
          !field.loadAsDropDown &&
          !field.isHidden
        " appearance="outline">
        <ng-container *ngIf="(addEditFlag || editViewFlag) && field.showAsInput">
          <!-- Label for field type lookup -->
          <div class="d-flex with-hlp-ic">
            <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:
              <span *ngIf="field.isRequired" style="color: #f44336">*</span>
              <ngx-help *ngIf="tutorials | helpPipe : field.label" [tutorial]="tutorials | helpPipe : field.label">
              </ngx-help>
            </span>
          </div>

          <!-- Field input for type of lookup -->
          <div class="select-new" [ngClass]="{
              l: field.fieldSize == 'large',
              s_s: field.fieldSize == 'tiny',
              s: field.fieldSize == 'small',
              m: field.fieldSize == 'medium',
              'mat-form-field-invalid':
                !this.lookupValue[field.name].length &&
                formSubmitted &&
                field.isRequired,
              'custom-multiple-select': field.allowMultipleValues
            }">
            <mat-label>{{ field.label }} </mat-label>

            <ng-select [items]="filteredOptions[field.name]" bindLabel="value" #in
              (keyup)="dynamicSearch($event, field)" appendTo="#{{ field.name }}_{{ tableName.replace(' ', '_') }}_{{
                currentTimeStamp
              }}" [notFoundText]="dropDownText[field.name]" (ngModelChange)="updatedVal($event, field.name)"
              [ngModelOptions]="{ standalone: true }" [id]="
                field.name +
                '_' +
                tableName.replace(' ', '_') +
                '_' +
                currentTimeStamp
              " placeholder="{{ field.label }}" [compareWith]="compareFn"
              [maxSelectedItems]="!field.allowMultipleValues ? 1 : undefined" (change)="
                onSelectionChange(
                  lookupValue[field.name],
                  field,
                  field.sequence
                )
              " [multiple]="true" [(ngModel)]="lookupValue[field.name]" [readonly]="
                field.isReadOnly ||
                (field.onlyEditInAdd ? (id ? true : false) : false)
              " clearable="true" class="ng-select-latest" [ngClass]="{
                'single-select':
                  field.allowMultipleValues == null ||
                  field.allowMultipleValues == false
              }">
              <ng-template ng-option-tmp let-item="item">
                <div>
                  <span [innerHTML]="item.value | renderImage : true"></span>
                </div>
              </ng-template>
              <ng-template ng-footer-tmp>
                <div *ngIf="loadingAPI" [nbSpinner]="true" nbSpinnerStatus="info">
                  Loading ...
                </div>
                <div (click)="in.close(); addSubForm(field, field.sequence)" class="hover-addnew">
                  Add New {{ field.lookupTableName | ngxPlural : 1000 }}
                </div>
              </ng-template>
              <ng-template ng-label-tmp let-item="item">
                <div (click)="
                    onShowLookupDetail(
                      $event,
                      field,
                      item,
                      item.id,
                      editNotLoadAsDropdownLookupPopover,
                      'editNotLoadAsDropdownLookupPopover'
                    )
                  " [ngbPopover]="templateRef5" placement="bottom" container="body"
                  #editNotLoadAsDropdownLookupPopover="ngbPopover" [autoClose]="'outside'">
                  <i class="fa fa-window-close" (click)="removeSelected(item, field)">x</i>
                  <b style="font-size: 16px" [innerHTML]="item.value | renderImage : true"></b>
                </div>
              </ng-template>
            </ng-select>
          </div>

          <ng-template #templateRef5>
            <ngx-lookup-modal-detail *ngIf="lookupDetailClicked"
              (viewModalEmit)="onEmitFromLookupPopoverForModal($event)" [data]="lookupDetailItem" [reference]="ref"
              [tableIdFromView]="field.lookupTableId" [fromView]="true" [name]="field.name"
              [tableData]="tableDataForForms" [dataForLookupDetail]="dataForLookupDetail"
              [tableName]="tableForLookupDetail" [formColumns]="formColumns">
            </ngx-lookup-modal-detail>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="viewFlag || !field.showAsInput">
          <!-- Label for field type lookup -->
          <div class="d-flex">
            <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label
              }}:</span>

            <!-- View Field for type lookup -->
            <div matPrefix class="mat-matPrefix" *ngIf="field.icon">
              <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
            </div>
          </div>

          <div class="chipset-custom-main" *ngIf="viewFlag && lookupDataObject && lookupDataObject[field.name]">
            <div style="cursor: pointer" (click)="
                onShowLookupDetail(
                  $event,
                  field,
                  Data,
                  val.lookupId,
                  notLoadAsDropdownLookupPopover,
                  'notLoadAsDropdownLookupPopover'
                )
              " [ngbPopover]="templateRef1" placement="bottom" container="body"
              #notLoadAsDropdownLookupPopover="ngbPopover" [autoClose]="'outside'"
              *ngFor="let val of lookupDataObject[field.name]" class="chipset-custom edit-class-form dot_line">
              <ng-container *ngFor="let value of val.valueToDisplay; let ind = index">
                <span *ngIf="ind == 0 && ind == val.valueToDisplay.length - 1" [innerHTML]="value | renderImage"></span>
                <span *ngIf="ind != 0 && ind == val.valueToDisplay.length - 1" [innerHTML]="value | renderImage"></span>
                <span *ngIf="ind != 0 && ind != val.valueToDisplay.length - 1" [innerHTML]="value | renderImage"></span>
                <span *ngIf="ind == 0 && ind != val.valueToDisplay.length - 1" [innerHTML]="value | renderImage"></span>
              </ng-container>
            </div>
          </div>

          <div class="chipset-custom-main" *ngIf="!viewFlag">
            <div style="cursor: pointer" (click)="
                onShowLookupDetail(
                  $event,
                  field,
                  val,
                  val.id,
                  notLoadAsDropdownLookupPopover,
                  'notLoadAsDropdownLookupPopover'
                )
              " [ngbPopover]="templateRef1" placement="bottom" container="body"
              #notLoadAsDropdownLookupPopover="ngbPopover" [autoClose]="'outside'"
              *ngFor="let val of lookupValue[field.name]" class="chipset-custom edit-class-form dot_line">
              <span>{{ val.value }}</span>
            </div>
          </div>

          <ng-template #templateRef1>
            <ngx-lookup-modal-detail *ngIf="lookupDetailClicked" id="pop-open1"
              (viewModalEmit)="onEmitFromLookupPopoverForModal($event)" [data]="lookupDetailItem" [reference]="ref"
              [tableIdFromView]="field.lookupTableId" [fromView]="true" [name]="fieldNameForLookup"
              [tableData]="tableDataForForms" [dataForLookupDetail]="dataForLookupDetail"
              [tableName]="tableForLookupDetail" [formColumns]="formColumns">
            </ngx-lookup-modal-detail>
          </ng-template>
        </ng-container>
      </ng-container>

      <ng-container class="fltt-left" *ngIf="
          field.type == 'lookup' &&
          show[field.name] &&
          field.loadAsDropDown &&
          !field.isHidden
        " appearance="outline">
        <ng-container *ngIf="(addEditFlag || editViewFlag) && field.showAsInput">
          <!-- Label for field type lookup -->
          <div class="d-flex with-hlp-ic">
            <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:
              <span *ngIf="field.isRequired" style="color: #f44336">*</span>
              <ngx-help *ngIf="tutorials | helpPipe : field.label" [tutorial]="tutorials | helpPipe : field.label">
              </ngx-help>
            </span>
          </div>

          <!-- Field input for type of lookup -->
          <div class="select-new" [ngClass]="{
              l: field.fieldSize == 'large',
              s_s: field.fieldSize == 'tiny',
              s: field.fieldSize == 'small',
              m: field.fieldSize == 'medium',
              'mat-form-field-invalid':
                !this.lookupValue[field.name].length &&
                formSubmitted &&
                field.isRequired,
              'custom-multiple-select': field.allowMultipleValues
            }">
            <mat-label>{{ field.label }} </mat-label>

            <ng-select [items]="
                filteredOptions[field.name]
                  | filter : lookupValue[field.name] : in.searchTerm
              " bindLabel="value" #in appendTo="#{{ field.name }}_{{ tableName.replaceAll(' ', '_') }}_{{
                currentTimeStamp
              }}" [notFoundText]="'No record found'" (ngModelChange)="updatedVal($event, field.name)"
              [ngModelOptions]="{ standalone: true }" [id]="
                field.name +
                '_' +
                tableName.replaceAll(' ', '_') +
                '_' +
                currentTimeStamp
              " placeholder="{{ field.label }}" [compareWith]="compareFn"
              [maxSelectedItems]="!field.allowMultipleValues ? 1 : undefined" (change)="
                onSelectionChange(
                  lookupValue[field.name],
                  field,
                  field.sequence
                )
              " [multiple]="true" [(ngModel)]="lookupValue[field.name]" [readonly]="
                field.isReadOnly ||
                (field.onlyEditInAdd ? (id ? true : false) : false)
              " clearable="true" class="ng-select-latest" [ngClass]="{
                'single-select':
                  field.allowMultipleValues == null ||
                  field.allowMultipleValues == false
              }">
              <!-- <ng-template ng-option-tmp let-item="item">
                <div>
                  <pre [innerHTML]="item.value | renderImage"></pre>
                </div>
              </ng-template> -->
              <ng-template ng-option-tmp let-item="item" let-index="index" let-item$="item$">
                <div *ngIf="item.value">
                  <input type="checkbox" [checked]="item$.selected" />
                  <span class="checkmark"></span>
                  <label class="form-check-label ml-2" [innerHTML]="item.value | renderImage : true"></label>
                </div>
              </ng-template>
              <ng-template ng-footer-tmp>
                <div *ngIf="loadingAPI" [nbSpinner]="true" nbSpinnerStatus="info">
                  Loading ...
                </div>
                <div (click)="in.close(); addSubForm(field, field.sequence)" class="hover-addnew">
                  Add New {{ field.lookupTableName | ngxPlural : 1000 }}
                </div>
              </ng-template>
              <ng-template ng-label-tmp let-item="item">
                <div (click)="
                    onShowLookupDetail(
                      $event,
                      field,
                      item,
                      item.id,
                      editLoadAsDropdownLookupPopover,
                      'editLoadAsDropdownLookupPopover'
                    )
                  " [ngbPopover]="templateRef4" placement="bottom" container="body"
                  #editLoadAsDropdownLookupPopover="ngbPopover" [autoClose]="'outside'">
                  <i class="fa fa-window-close" (click)="removeSelected(item, field)">x</i>
                  <b style="font-size: 16px" [innerHTML]="item.value | renderImage : true"></b>
                </div>
              </ng-template>
            </ng-select>
          </div>

          <ng-template #templateRef4>
            <ngx-lookup-modal-detail *ngIf="lookupDetailClicked"
              (viewModalEmit)="onEmitFromLookupPopoverForModal($event)" [data]="lookupDetailItem" [reference]="ref"
              [tableIdFromView]="field.lookupTableId" [fromView]="true" [name]="field.name"
              [tableData]="tableDataForForms" [dataForLookupDetail]="dataForLookupDetail"
              [tableName]="tableForLookupDetail" [formColumns]="formColumns">
            </ngx-lookup-modal-detail>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="viewFlag || !field.showAsInput">
          <!-- Label for field type lookup -->
          <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label
            }}:</span>

          <!-- View Field for type lookup -->
          <div matPrefix class="mat-matPrefix" *ngIf="field.icon">
            <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
          </div>
          <div class="chipset-custom-main" *ngIf="viewFalg && lookupDataObject && lookupDataObject[field.name]">
            <div style="cursor: pointer" (click)="
                onShowLookupDetail(
                  $event,
                  field,
                  Data,
                  val.lookupId,
                  loadAsDropdownLookupPopover,
                  'loadAsDropdownLookupPopover'
                )
              " #loadAsDropdownLookupPopover="ngbPopover" [ngbPopover]="templateRef3" container="body"
              placement="bottom" [autoClose]="'outside'" *ngFor="let val of lookupDataObject[field.name]"
              class="chipset-custom edit-class-form dot_line">
              <!-- <mat-chip-list aria-label="Fish selection">
            <mat-chip *ngFor="let value of val.valueToDisplay; let ind = index">{{val.isPhoneField[val.idFields[ind]] ? (value | phone) : value}}</mat-chip>
          </mat-chip-list> -->
              <ng-container *ngFor="let value of val.valueToDisplay; let ind = index">
                <span *ngIf="ind == 0 && ind == val.valueToDisplay.length - 1" [innerHTML]="value | renderImage"></span>
                <span *ngIf="ind != 0 && ind == val.valueToDisplay.length - 1" [innerHTML]="value | renderImage"></span>
                <span *ngIf="ind != 0 && ind != val.valueToDisplay.length - 1" [innerHTML]="value | renderImage"></span>
                <span *ngIf="ind == 0 && ind != val.valueToDisplay.length - 1" [innerHTML]="value | renderImage"></span>
              </ng-container>
            </div>
          </div>

          <div class="chipset-custom-main" *ngIf="!viewFalg">
            <div style="cursor: pointer" (click)="
                onShowLookupDetail(
                  $event,
                  field,
                  val,
                  val.id,
                  loadAsDropdownLookupPopover,
                  'loadAsDropdownLookupPopover'
                )
              " #loadAsDropdownLookupPopover="ngbPopover" [ngbPopover]="templateRef3" container="body"
              placement="bottom" [autoClose]="'outside'" *ngFor="let val of lookupValue[field.name]"
              class="chipset-custom edit-class-form dot_line">
              <span>{{ val.value }}</span>
            </div>
          </div>

          <ng-template #templateRef3>
            <ngx-lookup-modal-detail *ngIf="lookupDetailClicked"
              (viewModalEmit)="onEmitFromLookupPopoverForModal($event)" [data]="lookupDetailItem" [reference]="ref"
              [tableIdFromView]="field.lookupTableId" [fromView]="true" [name]="fieldNameForLookup"
              [tableData]="tableDataForForms" [dataForLookupDetail]="dataForLookupDetail"
              [tableName]="tableForLookupDetail" [formColumns]="formColumns">
            </ngx-lookup-modal-detail>
          </ng-template>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="field.type == 'file' && show[field.name] && !field.isHidden">
        <!-- Label for field type file -->
        <div class="d-flex with-hlp-ic">
          <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:
            <span *ngIf="field.isRequired" style="color: #f44336">*</span>
            <ngx-help *ngIf="tutorials | helpPipe : field.label" [tutorial]="tutorials | helpPipe : field.label">
            </ngx-help>
          </span>
        </div>

        <div class="file_upload" [class.active]="isActive" (dragover)="onDragOver($event)"
          (drop)="onDrop($event, field.name)" (dragleave)="onDragLeave($event)">
          <div class="files">
            <div class="upload_file">
              <div class="droparea">
                <input type="file" name="{{ field.name }}" class="browse-file"
                  (change)="onSelectedFile($event, field.name)" multiple [readonly]="
                    field.isReadOnly ||
                    (field.onlyEditInAdd ? (id ? true : false) : false)
                  " />
                <span class="mat-custom material-icons txt-clr">
                  cloud_upload
                </span>
              </div>
            </div>
            <div class="w_100" *ngIf="
                field.type === 'file' &&
                uploadedFiles &&
                uploadedFiles[field.name]
              ">
              <div class="s_file">
                <div class="inner_files edit_file">
                  <span class="file_name edit_file_inner" *ngFor="
                      let f of uploadedFiles[field.name];
                      let index = index
                    ">
                    <span class="{{ getFileExtension(f) }}"></span>&nbsp;&nbsp;
                    <span class="text_inner">
                      {{ getFileName(f) }}
                    </span>
                    <span class="flex_space"></span>
                    <div class="delete_icon" (click)="onDelete(index, field.name)">
                      <img src="assets/images/close.png" />
                    </div>
                  </span>
                </div>
              </div>
              <div>
                <ngx-custom-progress-bar *ngIf="uploadProgress > 0" aria-valuenow="25" aria-valuemin="0"
                  aria-valuemax="100" [value]="uploadProgress" [systemConfigColor]="progressColor">
                  {{ uploadProgress }}%</ngx-custom-progress-bar>
              </div>
            </div>
          </div>
          <!-- View Field for type file -->
          <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
            <!-- <img class="
            mat-icon
            notranslate
            material-icons
            mat-icon-no-color
            top04
          " src="{{ field.icon }}" /> -->
          </div>
          <ng-container *ngIf="
              uploadedFiles &&
              uploadedFiles[field.name] &&
              uploadedFiles[field.name].length
            ">
            <div class="file-with-name" *ngFor="let file of uploadedFiles[field.name]; let i = index">
              <span style="cursor: pointer" [nbPopover]="templateRefInside" placement="bottom" container="body"
                class="material-icons-outlined pull-right three-dots">more_vert</span>
              <ng-template #templateRefInside>
                <ul (click)="$event.stopPropagation()" class="more-optiocon">
                  <li>
                    <a (mouseover)="hovered = i" (mouseleave)="hover = -1" [ngStyle]="{
                        color: hovered === i ? systemConfigColor : 'black'
                      }" (click)="
                        identifyAction(
                          'Delete',
                          file.toString().split('/').pop(),
                          i,
                          field
                        )
                      ">Delete</a>
                  </li>

                  <li>
                    <a [href]="S3 + file.toString().split('/').pop()" (click)="
                        identifyAction(
                          'Download',
                          file.toString().split('/').pop(),
                          i,
                          field
                        )
                      " [ngStyle]="{
                        color: hovered === i ? systemConfigColor : 'black'
                      }" download>Download</a>
                  </li>

                  <!-- <li style="cursor: pointer;" (click)="downloadFiles(S3+file.toString().split('/').pop(),file)"
                    [ngStyle]="{'color': hovered===i  ? systemConfigColor:'black'}">
                    Download
                  </li> -->
                </ul>
              </ng-template>

              <!-- <ng-container>

                <span (click)="onFilePreview(S3+file.toString().split('/').pop(),field,i)"
                  class="{{ getFileExtension(file) }}"></span>&nbsp;&nbsp;

              </ng-container> -->

              <ng-container ngSwitch="{{ file.toString().split('.').pop() }}">
                <ng-container *ngSwitchCase="'docx'">
                  <img (click)="
                      onFilePreview(
                        S3 + file.toString().split('/').pop(),
                        field,
                        i
                      )
                    " *ngIf="file.toString().split('.').pop() == 'docx'"
                    src="https://cavalry.s3.amazonaws.com/doc-icon.png" />
                </ng-container>

                <ng-container *ngSwitchCase="'doc'">
                  <img (click)="
                      onFilePreview(
                        S3 + file.toString().split('/').pop(),
                        field,
                        i
                      )
                    " *ngIf="file.toString().split('.').pop() == 'doc'"
                    src="https://cavalry.s3.amazonaws.com/doc-icon.png" />
                </ng-container>

                <ng-container *ngSwitchCase="'xls'">
                  <img (click)="
                      onFilePreview(
                        S3 + file.toString().split('/').pop(),
                        field,
                        i
                      )
                    " *ngIf="file.toString().split('.').pop() == 'xls'"
                    src="https://cavalry.s3.amazonaws.com/xls-icon.png" />
                </ng-container>

                <!-- <ng-container *ngSwitchCase="'csv'">

                  <img (click)="onFilePreview(S3+file.toString().split('/').pop(),field,i)"
                    *ngIf="file.toString().split('.').pop() == 'csv'"
                    src="https://cavalry.s3.amazonaws.com/xls-icon.png" />

                </ng-container> -->

                <ng-container *ngSwitchCase="'xlsx'">
                  <img (click)="
                      onFilePreview(
                        S3 + file.toString().split('/').pop(),
                        field,
                        i
                      )
                    " *ngIf="file.toString().split('.').pop() == 'xlsx'"
                    src="https://cavalry.s3.amazonaws.com/xls-icon.png" />
                </ng-container>

                <ng-container *ngSwitchCase="'pdf'">
                  <img (click)="
                      onFilePreview(
                        S3 + file.toString().split('/').pop(),
                        field,
                        i
                      )
                    " *ngIf="file.toString().split('.').pop() == 'pdf'"
                    src="https://cavalry.s3.amazonaws.com/pdf-icon.png" />
                </ng-container>

                <ng-container *ngSwitchCase="'png'">
                  <img (click)="
                      onFilePreview(
                        S3 + file.toString().split('/').pop(),
                        field,
                        i
                      )
                    " *ngIf="file.toString().split('.').pop() == 'png'"
                    [src]="S3 + file.toString().split('/').pop()" />
                </ng-container>

                <ng-container *ngSwitchCase="'jpg'">
                  <img (click)="
                      onFilePreview(
                        S3 + file.toString().split('/').pop(),
                        field,
                        i
                      )
                    " *ngIf="file.toString().split('.').pop() == 'jpg'"
                    [src]="S3 + file.toString().split('/').pop()" />
                </ng-container>

                <ng-container *ngSwitchCase="'jpeg'">
                  <img (click)="
                      onFilePreview(
                        S3 + file.toString().split('/').pop(),
                        field,
                        i
                      )
                    " *ngIf="file.toString().split('.').pop() == 'jpeg'"
                    [src]="S3 + file.toString().split('/').pop()" />
                </ng-container>

                <ng-container *ngSwitchCase="'wav'">
                  <span class="material-icons material-symbols-outlined" (click)="
                      onFilePreview(
                        S3 + file.toString().split('/').pop(),
                        field,
                        i
                      )
                    " *ngIf="file.toString().split('.').pop() == 'wav'">
                    music_note
                  </span>
                </ng-container>

                <ng-container *ngSwitchCase="'mp3'">
                  <span class="material-icons material-symbols-outlined" (click)="
                      onFilePreview(
                        S3 + file.toString().split('/').pop(),
                        field,
                        i
                      )
                    " *ngIf="file.toString().split('.').pop() == 'mp3'">
                    music_note
                  </span>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  <!-- <a [href]="S3+file.toString().split('/').pop()"> -->
                  <span (click)="
                      onFilePreview(
                        S3 + file.toString().split('/').pop(),
                        field,
                        i
                      )
                    " class="{{ getFileExtension(file) }}"></span>
                  <!-- </a> -->
                </ng-container>
              </ng-container>

              <div class="only-file-name" [title]="file.toString().split('/').pop()">
                {{ getFileName(file.toString().split("/").pop()) }}
              </div>
            </div>
          </ng-container>
        </div>

        <div>
          <nb-progress-bar class="progress_bar" *ngIf="uploadProgress > 0" aria-valuenow="25" aria-valuemin="0"
            aria-valuemax="100" [value]="uploadProgress" status="primary">
            {{ uploadProgress }}%</nb-progress-bar>
        </div>
      </ng-container>

      <ng-container *ngIf="field.type === 'time' && show[field.name] && !field.isHidden">
        <ng-container *ngIf="(addEditFlag || editViewFlag) && field.showAsInput">
          <!-- Label for field type time -->
          <div class="d-flex with-hlp-ic">
            <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label }}:
              <span *ngIf="field.isRequired" style="color: #f44336">*</span>
              <ngx-help *ngIf="tutorials | helpPipe : field.label" [tutorial]="tutorials | helpPipe : field.label">
              </ngx-help>
            </span>
          </div>

          <!-- Field input for type of time -->
          <mat-form-field class="mb-20" appearance="outline" [ngClass]="[
              field.fieldSize == 'tiny'
                ? 's_s'
                : field.fieldSize == 'small'
                ? 's'
                : field.fieldSize == 'medium'
                ? 'm'
                : 'l',
              dynamicForm.get(field.name).invalid && formSubmitted
                ? 'mat-form-field-invalid'
                : '',
              field.icon ? 'with-icon' : '',
              field.isReadOnly ? 'is-read-only' : ''
            ]">
            <mat-label>{{ field.label }}</mat-label>
            <input matInput placeholder="{{ field.label }}" [type]="field.type" [placeholder]="field.label"
              [formControlName]="field.name" [readonly]="
                field.isReadOnly ||
                (field.onlyEditInAdd ? (id ? true : false) : false)
              " (keyup)="evalExpressionForFormulaField()" (ngModelChange)="evalExpressionForFormulaField()" />
          </mat-form-field>
        </ng-container>

        <ng-container *ngIf="viewFlag || !field.showAsInput">
          <!-- Label for field type time -->
          <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ field.label
            }}:</span>

          <!-- View Field for type time -->
          <div matPrefix *ngIf="field.icon" class="mat-matPrefix">
            <img class="mat-icon notranslate material-icons mat-icon-no-color top04" src="{{ field.icon }}" />
          </div>
          <div class="edit-class-form" *ngIf="dynamicForm.get(field.name).value"
            [innerHTML]="dynamicForm.get(field.name).value"></div>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container class="l" appearance="outline"
      *ngIf="field.type === 'refButton' && show[field.name] && !field.isHidden">
      <ng-container *ngIf="addEditFlag || editViewFlag">
        <!-- Field for Referrence Button -->
        <ng-container *ngIf="list && list.length">
          <div class="pos-rel" *ngFor="let ele of list; let i = index">
            <div class="row heading">
              <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ ele.name
                }}</span>
            </div>
            <div class="field-box">
              <div class="ui-label" *ngFor="let item of ele.value; let row = index">
                <ng-container *ngIf="
                    (item.idField && item.idField.length) ||
                    (item.others && item.others.length)
                  ">
                  <!-- <ng-container *ngFor="let val of item.idField">
                  <span *ngFor="let valForId of val" class="ui-content1"
                    [innerHTML]="valForId.value[0]"></span>
                </ng-container>
                <ng-container *ngFor="let val of item.others">
                  <span *ngFor="let valForOther of val" class="ui-content2"
                    [innerHTML]="valForOther.value[0]"></span>
                </ng-container> -->
                  <span *ngFor="let val of item.idField" class="ui-content1" [innerHTML]="val"></span>
                  <span *ngFor="let value of item.others" class="ui-content2" [innerHTML]="value"></span>
                  <img class="cross-pos" src="/assets/images/ic_close_24px.svg"
                    (click)="closeList(ele.value, i, row)" />
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
        <button (click)="addRef(field.name)" nbButton matRipple
          class="btn btn-primary filled-dynamic-color reference-btn">
          + {{ field.label }}
        </button>
      </ng-container>

      <ng-container *ngIf="viewFlag">
        <ng-container *ngIf="list && list.length">
          <div class="pos-rel" *ngFor="let ele of list; let i = index">
            <div class="row heading">
              <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ ele.name
                }}</span>
            </div>
            <div class="field-box">
              <div (click)="
                  onShowLookupDetail(
                    $event,
                    ele,
                    Data,
                    item.id,
                    referenceLookupPopover,
                    'referenceLookupPopover'
                  )
                " [autoClose]="'outside'" #referenceLookupPopover="ngbPopover" [ngbPopover]="templateRef2"
                container="body" placement="bottom" style="cursor: pointer"
                *ngFor="let item of ele.value; let row = index" class="dot_line edit-class-form">
                <!-- <ng-container *ngFor="let val of item.idField">
                <span *ngFor="let valForId of val" class="ui-content1">{{valForId.isPhone ? (valForId.value[0]
                  | phone) : valForId.value[0]}}</span>
              </ng-container>
              <ng-container *ngFor="let val of item.others">
                <span *ngFor="let valForOther of val" class="ui-content2">{{valForOther.isPhone ?
                  (valForOther.value[0] | phone) : valForOther.value[0]}}</span>
              </ng-container> -->
                <span *ngFor="let val of item.idField" class="ui-content1" [innerHTML]="val"></span>
                <span *ngFor="let value of item.others" class="ui-content2" [innerHTML]="value"></span>
              </div>
            </div>
          </div>
          <ng-template #templateRef2>
            <ngx-lookup-modal-detail id="pop-open2" (viewModalEmit)="onEmitFromLookupPopoverForModal($event)"
              [data]="lookupDetailItem" [reference]="ref" [tableIdFromView]="tableIdFromView" [fromView]="true"
              [name]="fieldNameForLookup" [tableData]="tableDataForForms" [dataForLookupDetail]="dataForLookupDetail"
              [tableName]="tableForLookupDetail" [formColumns]="formColumns">
            </ngx-lookup-modal-detail>
          </ng-template>
        </ng-container>
        <button (click)="addRef(field.name)" nbButton matRipple
          class="btn btn-primary filled-dynamic-color reference-btn">
          + Add Reference
        </button>
      </ng-container>
    </ng-container>

    <ng-container class="l" appearance="outline" *ngIf="field.type === 'refRecords' && !field.isHidden">
      <ng-container *ngIf="viewFlag">
        <ng-container *ngIf="refRecordList && refRecordList.length">
          <div class="pos-rel" *ngFor="let ele of refRecordList; let i = index">
            <div class="row heading">
              <span class="new-label label" [ngClass]="{ 'isprovide-label': field.isProvideButton }">{{ ele.name
                }}</span>
            </div>
            <div class="field-box">
              <div (click)="
                  onShowLookupDetail(
                    $event,
                    ele,
                    Data,
                    item.id,
                    referenceRecordLookupPopover,
                    'referenceRecordLookupPopover',
                    'refRecord'
                  )
                " [autoClose]="'outside'" container="body" placement="bottom" style="cursor: pointer"
                #referenceRecordLookupPopover="ngbPopover" [ngbPopover]="templateRef3"
                *ngFor="let item of ele.value; let row = index" class="dot_line edit-class-form">
                <span *ngFor="let val of item.idFields" class="ui-content1" [innerHTML]="val"></span>
                <span *ngFor="let value of item.otherFields" class="ui-content2" [innerHTML]="value"></span>
              </div>
            </div>
          </div>
          <ng-template #templateRef3>
            <ngx-lookup-modal-detail id="pop-open2" (viewModalEmit)="onEmitFromLookupPopoverForModal($event)"
              [data]="lookupDetailItem" [reference]="ref" [tableData]="tableDataForForms" [fromRefRecord]="true"
              [tableName]="tableForLookupDetail">
            </ngx-lookup-modal-detail>
          </ng-template>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container class="l" appearance="outline" *ngIf="field.isHidden">
      <input type="hidden" [formControlName]="field.name" />
    </ng-container>
  </ng-template>
</form>
