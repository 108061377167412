import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as io from 'socket.io-client';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root',
})

export class SocketService{

    socket: any;
    readonly uri: string = environment.socketUrl;
    hostname = window.location.host;

    constructor() {
        this.socket = io(this.uri, {
          reconnection: false, // for temporary only for demo
          reconnectionDelay: 1000,
          path: "/socket.io",
          transports: ["websocket"],
          secure: true,
          query: {
            authorization: localStorage.getItem("token"),
          },
        });
       // console.log('origin => ', this.hostname)
    }

    listen(eventName: string){
        return new Observable((subscriber) => {
            this.socket.on(eventName, (data) => {
                subscriber.next(data);
            })
        });
    }

    emit(eventName: string, data: any){
      //  let host = (this.hostname == 'localhost') ? 'localhost:4200' :  this.hostname;
        this.socket.emit(eventName, data,this.hostname);
    }

    broadcastemit(eventName: string, data: any){
        this.socket.broadcastemit(eventName, data);
    }

}
