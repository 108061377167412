import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "@app/shared/services";
import { debug } from "console";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}
  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot,
  // ): Observable<boolean> | Promise<boolean> | boolean {
  //   const token = localStorage.getItem('userToken');
  //   if (!token) {
  //     this.authService.checkTokenExpiration();
  //     this.router.navigate(['pages']);
  //     return false;
  //   }
  //   return true;
  // }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = localStorage.getItem("userToken");
    if (token) {
      this.authService.checkTokenExpiration();
      return true;
    } else {
      if (window.location.hash) {
        const redirectURL = decodeURIComponent(window.location.hash);
        if(window.location.search && decodeURIComponent(window.location.search).startsWith('?token=')) {
          const queryParams = decodeURIComponent(window.location.search).replace('?token=','');
          this.router.navigate(["/auth/login", {'token':queryParams}]);
        } else {
          this.router.navigate(["/auth/login", { redirectURL }]);
        }
      } else {
        this.router.navigate(["/auth/login"]);
      }
      return false;
    }
  }
}

@Injectable({ providedIn: "root" })
export class CompanyRegistrationGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log(window.location.hostname);

    // if (
    //   window.location.hostname == "hosting.synccos.com" ||
    //   window.location.hostname == "synccos.com"
    // ) {
    // production check
    if (
      window.location.hostname == "hosting.synccos.com" ||
      window.location.hostname == "synccos.com" ||
      window.location.href.includes("localhost:4200") ||
      window.location.hostname == "dev.synccos.com"
    ) {
      // localcheck
      return true;
    } else {
      console.log("loop 1 else");
      this.router.navigate(["/auth/login"]);
      return false;
    }
    // return true
  }
}

@Injectable({ providedIn: "root" })
export class checkSynccosGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log(window.location.hostname);

    // if (
    //   !(
    //     window.location.hostname == "hosting.synccos.com" ||
    //     window.location.hostname == "synccos.com"
    //   )
    // ) {
    // production check
    if (
      !(
        window.location.hostname == "hosting.synccos.com" ||
        window.location.hostname == "synccos.com" ||
        window.location.hostname == "dev.synccos.com" ||
        window.location.href.includes("localhost:4200")
      )
    ) {
      // localcheck
      return true;
    } else {
      console.log("loop 2 else");
      this.router.navigate(["/"]);
      return true;
    }
    // return true
  }
}
